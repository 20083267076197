import React, { useEffect, useState } from 'react';
// import { useHistory } from 'react-router-dom';
import styles from './SetPreferences.module.css';
import UncoverLeft from './UncoverLeft';
import Line from '../..//icons/AUTH/signin/Line 2.svg';
import Line2 from '../..//icons/AUTH/signin/Line 3.svg';

import men from '../..//icons/AUTH/signin/men.svg';
import step2 from '../..//icons/AUTH/signin/curentstep2.svg';
import { Link } from 'react-router-dom';

import step3 from '../..//icons/AUTH/signin/step3.svg';
import back from '../..//icons/back.svg';
import GenreCard from './GenreCard';

import useApiUtils from '../../useApiUtils';

import { useRecoilState } from 'recoil';
import { userInfoState } from '../../state';
import CircularProgress from '@mui/material/CircularProgress';

import { useLocation, useNavigate } from 'react-router-dom';

import { renderCenteredAlert } from '../CenteredAlert';

import { fictionGenreList, nonFictionGenreList } from '../../genreLists';

const SetPreferences = () => {
  const [saveButtonState, setSaveButtonState] = useState('normal'); // 'normal', 'saving', 'success', 'failure'

  // const history = useHistory();
  const [selectedFictionGenres, setSelectedFictionGenres] =
    useState(fictionGenreList);
  const [selectedNonFictionGenres, setSelectedNonFictionGenres] =
    useState(nonFictionGenreList);
  const [superadmin, setSuperadmin] = useState(false);

  const location = useLocation();

  const [wordCountMin, setWordCountMin] = useState(0);
  const [wordCountMax, setWordCountMax] = useState(10000000);

  const navigate = useNavigate();
  const [userInfo, setUserInfo] = useRecoilState(userInfoState); // Update the path

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const superadmin = queryParams.get('superadmin');
    if (superadmin === 'true') {
      setSuperadmin(true);
    }
  }, []);

  useEffect(() => {
    if (userInfo && userInfo.Preferences) {
      if (userInfo.Preferences.genres) {
        const newGenres = selectedFictionGenres.map((genre) => {
          if (userInfo.Preferences.genres.includes(genre.genre)) {
            return {
              ...genre,
              selected: true,
            };
          }
          return genre;
        });
        setSelectedFictionGenres(newGenres);
      }
      // do it for non fiction
      if (userInfo.Preferences.non_fiction_genres) {
        const newGenres = selectedNonFictionGenres.map((genre) => {
          if (userInfo.Preferences.non_fiction_genres.includes(genre.genre)) {
            return {
              ...genre,
              selected: true,
            };
          }
          return genre;
        });
        setSelectedNonFictionGenres(newGenres);
      }

      if (userInfo.Preferences.wordcount_min) {
        setWordCountMin(userInfo.Preferences.wordcount_min);
      }
      if (userInfo.Preferences.wordcount_max) {
        setWordCountMax(userInfo.Preferences.wordcount_max);
      }
    }
  }, [userInfo]);

  const {
    setPreferanses,
    // Add other functions from the hook if needed
  } = useApiUtils();

  const handleWordCountMinChange = (e) => {
    // Remove commas before updating state
    const rawValue = e.target.value.replace(/,/g, '');

    // Check if the value is a valid number
    const numberValue = Number(rawValue);

    // If it's not a valid number (NaN), do not update state or set a default value
    if (!isNaN(numberValue) && rawValue !== '') {
      setWordCountMin(numberValue);
    }
  };

  const handleWordCountMaxChange = (e) => {
    // Remove commas before updating state
    const rawValue = e.target.value.replace(/,/g, '');

    // Check if the value is a valid number
    const numberValue = Number(rawValue);

    // If it's not a valid number (NaN), do not update state or set a default value
    if (!isNaN(numberValue) && rawValue !== '') {
      setWordCountMax(numberValue);
    }
  };

  const handleBackClick = () => {
    navigate(`/signup${location.search}`);
  };

  const handleNextClick = async () => {
    if (saveButtonState === 'loading') {
      return;
    }
    //if non of the genre is selected, show error message
    if (
      selectedFictionGenres.filter((genre) => genre.selected).length === 0 &&
      selectedNonFictionGenres.filter((genre) => genre.selected).length === 0
    ) {
      renderCenteredAlert('Please select at least one genre');
      return;
    }

    if (wordCountMin === '') {
      renderCenteredAlert('Please enter minimum word count ');
      return;
    }
    if (wordCountMax === '') {
      renderCenteredAlert('Please enter maximum word count ');
      return;
    }
    if (!isValidWordCount()) {
      renderCenteredAlert(
        'Invalid word count range. Please check the minimum and maximum values. The minimum value should be greater than 0 and the maximum value should be less than 10000000 and greater than the minimum value.'
      );
      return;
    }
    setSaveButtonState('loading');

    // Construct the data to be sent to the backend API
    const dataToSend = {
      user_id: userInfo.user_id,
      genres: selectedFictionGenres
        .filter((genre) => genre.selected)
        .map((genre) => genre.genre),
      non_fiction_genres: selectedNonFictionGenres
        .filter((genre) => genre.selected)
        .map((genre) => genre.genre),

      wordcount_min: wordCountMin,
      wordcount_max: wordCountMax,
    };

    // Call the API
    try {
      await setPreferanses(dataToSend, false);
      navigate(`/set-wishlist${location.search}`);
      setSaveButtonState('normal');
    } catch (error) {
      setSaveButtonState('normal');
      renderCenteredAlert('Failed to save preferences');
    }
  };

  const handleNonFictionGenreFictionSelection = (genre) => {
    const newGenres = selectedNonFictionGenres.map((g) => {
      if (g.genre === genre) {
        return {
          ...g,
          selected: !g.selected,
        };
      }
      return g;
    });
    setSelectedNonFictionGenres(newGenres);
  };

  const handleGenreFictionSelection = (genre) => {
    const newGenres = selectedFictionGenres.map((g) => {
      if (g.genre === genre) {
        return {
          ...g,
          selected: !g.selected,
        };
      }
      return g;
    });
    setSelectedFictionGenres(newGenres);
  };

  // Function to handle selection changes

  const isValidWordCount = () => {
    const min = parseInt(wordCountMin, 10);
    const max = parseInt(wordCountMax, 10);
    return min >= 0 && max <= 10000000 && max >= min;
  };

  const getButtonText = () => {
    switch (saveButtonState) {
      case 'saving':
        return 'Saving...';
      case 'success':
        return 'Saved Successfully';
      case 'failure':
        return 'Failed to Save';
      case 'loading':
        return <CircularProgress size={15} color="inherit" />;
      default:
        return 'Next';
    }
  };

  return (
    <div className={styles.container}>
      <UncoverLeft />
      <div className={styles.logosignincontainer}>
        <div className={styles.signcontainer}>
          <div className={styles.hedder}>
            <div className={styles.steps}>
              <div className={styles.step1}>
                <div className={styles.step1circle}>
                  <img src={men} className={styles.men} />
                </div>
              </div>
              <div className={styles.line1}>
                <div className={styles.line1img}>
                  <img src={Line2} alt="line" />
                </div>
              </div>
              <div className={styles.step1}>
                <div className={styles.step2circle}>
                  <img src={step2} className={styles.menstep2} />
                </div>
                <div className={styles.step1text}>
                  step 2/3
                  <div className={styles.step1text1}>Set Your Preferences</div>
                </div>
              </div>
              <div className={styles.line1}>
                <div className={styles.line1img}>
                  <img src={Line} alt="line" />
                </div>
              </div>
              <div className={styles.step3}>
                <div className={styles.step3circle}>
                  <img src={step3} className={styles.menstep3} />
                </div>
              </div>
            </div>

            <div className={styles.signup}>
              <div className={styles.backtologin}>Already have an account?</div>
              <Link to="/" className={styles.linkto}>
                Log in
              </Link>
            </div>
          </div>
          <div className={styles.body}>
            <div className={styles.titlelogo}>
              <div className={styles.loginparttitle}>
                Automatic Matching Guidelines{' '}
              </div>
              <div className={styles.loginparttitle}>Genre and Word count </div>
            </div>

            <div className={styles.maindescription}>
              Prioritize your submissions. Manuscripts that do not meet your
              requirements will be automatically declined. You can still review
              them later.
            </div>

            <div className={styles.Qualification_Categories}>
              <div className={styles.category_content}>
                <div className={styles.titlesword}>Word count range</div>
                <div className={styles.description}>
                  Manuscripts with a word count not in this range will
                  automatically be declined.
                </div>

                <div className={styles['word-count-container']}>
                  <input
                    type="text"
                    value={new Intl.NumberFormat().format(wordCountMin)}
                    onChange={(e) => handleWordCountMinChange(e)}
                    placeholder="Min"
                    className={styles.input}
                  />
                  <div className={styles.rangeIndicator}>-</div>
                  <input
                    type="text"
                    value={new Intl.NumberFormat().format(wordCountMax)}
                    onChange={(e) => handleWordCountMaxChange(e)}
                    placeholder="Max"
                    className={styles.input}
                  />
                </div>

                <div className={styles.titles}>Select Your Wishlist</div>
                <div className={styles.description}>
                  Choose the book genres that you are interested in publishing:{' '}
                </div>
                <div className={styles.titles}>Fiction</div>
                <div className={styles.genres_container}>
                  {selectedFictionGenres.map((genre, index) => (
                    <GenreCard
                      key={index}
                      genre={genre.genre}
                      genre_image={genre.genre_image}
                      selected={genre.selected}
                      onClick={handleGenreFictionSelection}
                    />
                  ))}
                </div>
                <div className={styles.titles}>Non-Fiction</div>
                <div className={styles.genres_container}>
                  {selectedNonFictionGenres.map((genre, index) => (
                    <GenreCard
                      key={index}
                      genre={genre.genre}
                      genre_image={genre.genre_image}
                      selected={genre.selected}
                      onClick={handleNonFictionGenreFictionSelection}
                    />
                  ))}
                </div>
              </div>
            </div>
          </div>
          <div className={styles.fotter}>
            <div className={styles.pagenumber}>
              <div className={styles.pagenumberfirst}>2</div>/
              {superadmin ? '6' : '4'}
            </div>
            <div className={styles.buttoncontainer}>
              <div className={styles.buttoncontainerback}>
                <img src={back} className={styles.back} />
                <div
                  type="submit"
                  className={styles.buttonBack}
                  onClick={handleBackClick}
                >
                  <div className={styles.textbuttonBack}>Back</div>
                </div>
              </div>
              <div
                type="submit"
                className={`${styles.button}
                         ${
                           saveButtonState === 'success'
                             ? styles.successButton
                             : saveButtonState === 'failure'
                             ? styles.failureButton
                             : ''
                         }`}
                onClick={handleNextClick}
              >
                <div className={styles.textbutton}>{getButtonText()}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SetPreferences;

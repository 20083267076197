import { styled } from '@mui/system';
import { Button } from '@mui/material';

// Create a custom button with primary and secondary style variants
const SwButton = styled(Button)(({ theme, variant }) => ({
  backgroundColor:
    variant === 'secondary'
      ? '#d3d3d3' // Light gray for secondary button
      : '#1ceaa0', // Primary color
  textTransform: 'none', // Disable uppercase
  color: 'black',
  fontWeight: 'bold',
  fontFamily: 'Lato',
  borderRadius: '14px',
  alignSelf: 'center',
  //minWidth: '100px', // Set minimum width to keep button consistent
  minHeight: '36px', // Set minimum height to keep button consistent
  '&:hover': {
    backgroundColor:
      variant === 'secondary'
        ? '#b0b0b0' // Darker gray for secondary hover
        : '#17c78a', // Primary hover color
  },
  '&.Mui-disabled': {
    backgroundColor: '#cccccc', // Gray color for disabled state
    color: '#666666', // Muted text color when disabled
  },
}));

export default SwButton;

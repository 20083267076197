import styles from './Approve.module.css';
import { useState, useEffect } from 'react';
import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';
import Select from 'react-select';
import { useRecoilState } from 'recoil';
import {
  emailToApproveState,
  clickedManuscriptAtom,
  pendingProcessingState,
  userInfoState,
  manualLoginState
} from '../../../state';
import CircularProgress from '@mui/material/CircularProgress';

import x from '../../../icons/vuesax/outline/addx.svg';

import useApiUtils from '../../../useApiUtils';
import AvatarCostom from '../../../UI/AvatarCostom';
import { renderCenteredAlert } from '../../CenteredAlert';
const Approve = (props) => {
  const [userInfo, setUserInfo] = useRecoilState(userInfoState); // Update the path


  const [clickedManuscript, setClickedManuscript] = useRecoilState(
    clickedManuscriptAtom
  );
  const [manualLogin, setManualLogin] = useRecoilState(manualLoginState);

  const [saveButtonState, setSaveButtonState] = useState('normal'); // 'normal', 'saving', 'success', 'failure'

  const [pendingProcessing, setPendingProcessing] = useRecoilState(
    pendingProcessingState
  );

    useRecoilState(emailToApproveState);
  const {
    getManuscriptsInfo,
    initialgetBookChapter,
    get_book_scores,
    getbookgenrekeywords,
    sendRejection,
    sendApprove,
    // Add other functions from the hook if needed
  } = useApiUtils();



  const handleOut = () => {
    props.handleApprove(false);
  };

  const getButtonText = () => {
    switch (saveButtonState) {
      case 'saving':
        return 'Saving...';
      case 'success':
        return 'Saved Successfully';
      case 'failure':
        return 'Failed to Save';
      case 'loading':
        return <CircularProgress size={15} color="inherit" />;
      default:
        return 'Yes, Approve';
    }
  };

 
  const handleApproveBefore = async () => {
    if (saveButtonState === 'loading') {
      return;
    }
    setSaveButtonState('loading');
    await sendApprove(clickedManuscript.book_id);
    props.handleApprove(false);
    props.return(false);
  };


 
  return (
    <div className={manualLogin ?  styles.manualbackdrop : styles.backdrop}>
      <div className={styles.TopSection}>
        <div className={styles.buttonOut} onClick={handleOut}>
          <img className={styles.xbutton} src={x} />
        </div>

        <div className={styles.MiddleSection}>
          <div className={styles.ImageContainer}>
            <img
              className={styles.Image}
              src={clickedManuscript.image}
              alt=""
            />
          </div>
          <div className={styles.title}>
            {clickedManuscript.manuscript_name}
          </div>
          <div className={styles.outherName}>
            By{' '}
            <div className={styles.outher}>{clickedManuscript.author_name}</div>
          </div>
        </div>
        <div className={styles.subMiddleSection}>
          <div className={styles.ConfirmationTitle}>
            Are You Sure You Want to Approve This Manuscript?
          </div>
            <div className={styles.ConfirmationSubtitle1}>
            The manuscript status on your dashboard will be updated to Approved. 
             The author will not be notified.
            </div>
        </div>
      </div>
        <div className={styles.ButtonSection}>
          <div className={styles.YesButtonContainer} onClick={handleOut}>
            <div className={styles.yesButton}>No, Don’t Approve</div>
          </div>
          <div
            className={styles.NoButtonContainer}
            onClick={handleApproveBefore}
          >
            <div className={styles.noButton}>{getButtonText()}</div>
          </div>
        </div>
  
    </div>
  );
};

export default Approve;

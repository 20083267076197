import styles from './Wishlist.module.css';
import React, { useState, useEffect } from 'react';
import WishlistAccordion from './WishlistAccordion';

const Wishlist = (props) => {
  return (
    <div className={styles.logosignincontainer}>
      <div className={styles.signcontainer}>
        <div className={styles.body}>
          <div className={styles.titlelogo}>
            <div className={styles.loginparttitle}>Your Wishlist </div>
          </div>

          <div className={styles.maindescription}>
            Prioritize your submissions.
          </div>

          <div style={{ width: '100%' }}>
            <WishlistAccordion />
          </div>
        </div>
      </div>
    </div>
  );
};
export default Wishlist;

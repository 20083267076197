import styles from './Preferences.module.css';
import React, { useState, useEffect } from 'react';

import GenreCard from '../home/GenreCard';
import CategoriesCard from '../home/CategoriesCard';
import tooltipIcon from '../../icons/icons8-info (1).svg';

import { useRecoilState } from 'recoil';
import { companyInfoState, userInfoState } from '../../state';

import { pink } from '@mui/material/colors';
import Checkbox from '@mui/material/Checkbox';
import expend_score from '../../icons/expend_score copy 2.svg';
import despend from '../../icons/despend copy 2.svg';
import CircularProgress from '@mui/material/CircularProgress';

import useApiUtils from '../../useApiUtils';

import { useNavigate } from 'react-router-dom';
import { renderCenteredAlert } from '../../components/CenteredAlert';

import { fictionGenreList, nonFictionGenreList } from '../../genreLists';

const Preferences = (props) => {
  const [userInfo, setUserInfo] = useRecoilState(userInfoState); // Update the path
  const [saveButtonState, setSaveButtonState] = useState('normal'); // 'normal', 'saving', 'success', 'failure'

  const [selectedFictionGenres, setSelectedFictionGenres] =
    useState(fictionGenreList);
  const [selectedNonFictionGenres, setSelectedNonFictionGenres] =
    useState(nonFictionGenreList);

  const [wordCountMin, setWordCountMin] = useState(0);
  const [wordCountMax, setWordCountMax] = useState(10000000);

  const [keywordsList, setKeywordsList] = useState([]);

  const navigate = useNavigate();

  const {
    setPreferanses,

    // Add other functions from the hook if needed
  } = useApiUtils();

  const handleWordCountMinChange = (e) => {
    // Remove commas before updating state
    const rawValue = e.target.value.replace(/,/g, '');

    // Check if the value is a valid number
    const numberValue = Number(rawValue);

    // If it's not a valid number (NaN), do not update state or set a default value
    if (!isNaN(numberValue) && rawValue !== '') {
      setWordCountMin(numberValue);
    }
  };

  const handleWordCountMaxChange = (e) => {
    // Remove commas before updating state
    const rawValue = e.target.value.replace(/,/g, '');

    // Check if the value is a valid number
    const numberValue = Number(rawValue);

    // If it's not a valid number (NaN), do not update state or set a default value
    if (!isNaN(numberValue) && rawValue !== '') {
      setWordCountMax(numberValue);
    }
  };

  useEffect(() => {
    if (userInfo && userInfo.Preferences) {
      if (userInfo.Preferences.genres) {
        const newGenres = selectedFictionGenres.map((genre) => {
          if (userInfo.Preferences.genres.includes(genre.genre)) {
            return {
              ...genre,
              selected: true,
            };
          }
          return genre;
        });
        setSelectedFictionGenres(newGenres);
      }
      // do it for non fiction
      if (userInfo.Preferences.non_fiction_genres) {
        const newGenres = selectedNonFictionGenres.map((genre) => {
          if (userInfo.Preferences.non_fiction_genres.includes(genre.genre)) {
            return {
              ...genre,
              selected: true,
            };
          }
          return genre;
        });
        setSelectedNonFictionGenres(newGenres);
      }

      if (userInfo.Preferences.wordcount_min) {
        setWordCountMin(userInfo.Preferences.wordcount_min);
      }
      if (userInfo.Preferences.wordcount_max) {
        setWordCountMax(userInfo.Preferences.wordcount_max);
      }
    }
  }, [userInfo]);

  // Validation for word count
  const isValidWordCount = () => {
    const min = parseInt(wordCountMin, 10);
    const max = parseInt(wordCountMax, 10);
    return min >= 0 && max <= 10000000 && max >= min;
  };

  const handleNonFictionGenreFictionSelection = (genre) => {
    const newGenres = selectedNonFictionGenres.map((g) => {
      if (g.genre === genre) {
        return {
          ...g,
          selected: !g.selected,
        };
      }
      return g;
    });
    setSelectedNonFictionGenres(newGenres);
  };

  const handleGenreFictionSelection = (genre) => {
    const newGenres = selectedFictionGenres.map((g) => {
      if (g.genre === genre) {
        return {
          ...g,
          selected: !g.selected,
        };
      }
      return g;
    });
    setSelectedFictionGenres(newGenres);
  };

  const handleSavePreferanses = async () => {

    if (
      selectedFictionGenres.filter((genre) => genre.selected).length === 0 &&
      selectedNonFictionGenres.filter((genre) => genre.selected).length === 0
    ) {
      renderCenteredAlert('Please select at least one genre');
      return;
    }

    //if wordCountMin is empty, show error message
    if (wordCountMin === '') {
      renderCenteredAlert('Please enter minimum word count ');
      return;
    }
    if (wordCountMax === '') {
      renderCenteredAlert('Please enter maximum word count ');
      return;
    }
    if (!isValidWordCount()) {
      renderCenteredAlert(
        'Invalid word count range. Please check the minimum and maximum values. The minimum value should be greater than 0 and the maximum value should be less than 10000000 and greater than the minimum value.'
      );
      return;
    }
    if (saveButtonState === 'loading') {
      return;
    }

    setSaveButtonState('loading');

    // Construct the data to be sent to the backend API
    const dataToSend = {
      user_id: userInfo.user_id,
      // genres: selectedGenres.map(genre => genre.genre),
      // send onlt selected genres
      genres: selectedFictionGenres
        .filter((genre) => genre.selected)
        .map((genre) => genre.genre),
      non_fiction_genres: selectedNonFictionGenres
        .filter((genre) => genre.selected)
        .map((genre) => genre.genre),
      wordcount_min: wordCountMin,
      wordcount_max: wordCountMax,
    };
    try {
      await setPreferanses(dataToSend, true);
      setSaveButtonState('normal');
    } catch (error) {
      setSaveButtonState('normal');
    }
  };

  const getButtonText = () => {
    switch (saveButtonState) {
      case 'saving':
        return 'Saving...';
      case 'success':
        return 'Saved Successfully';
      case 'failure':
        return 'Failed to Save';
      case 'loading':
        return <CircularProgress size={15} color="inherit" />;
      default:
        return 'Save Changes';
    }
  };

  const handleEligiblePreferences = () => {
    // if this is a check so update allselectedEligibleList to true
    //  alert that this category cannot be unchecked
    renderCenteredAlert(
      'The Eligibility  is essential and cannot be deselected'
    );
    return;
  };

  return (
    <div className={styles.logosignincontainer}>
      <div className={styles.signcontainer}>
        <div className={styles.body}>
          <div className={styles.titlelogo}>
            <div className={styles.loginparttitle}>
              Automatic Matching Guidelines{' '}
            </div>
            <div className={styles.loginparttitle}>Genre and Word count </div>
          </div>

          <div className={styles.maindescription}>
            Prioritize your submissions. Manuscripts that do not meet your
            requirements will be automatically declined. You can still review
            them later.
          </div>

          <div className={styles.Qualification_Categories}>
            <div className={styles.category_content}>
              <div className={styles.titlesword}>Word count range</div>
              <div className={styles.description}>
                Manuscripts with a word count not in this range will
                automatically be declined.
              </div>

              <div className={styles['word-count-container']}>
                <input
                  type="text"
                  value={new Intl.NumberFormat().format(wordCountMin)}
                  onChange={(e) => handleWordCountMinChange(e)}
                  placeholder="Min"
                  className={styles.input}
                />
                <div className={styles.rangeIndicator}>-</div>
                <input
                  type="text"
                  value={new Intl.NumberFormat().format(wordCountMax)}
                  onChange={(e) => handleWordCountMaxChange(e)}
                  placeholder="Max"
                  className={styles.input}
                />
              </div>

              <div className={styles.titles}>
                Select the Genres that interest you
              </div>

              <div className={styles.titles}>Fiction</div>
              <div className={styles.genres_container}>
                {selectedFictionGenres.map((genre, index) => (
                  <GenreCard
                    key={index}
                    genre={genre.genre}
                    genre_image={genre.genre_image}
                    selected={genre.selected}
                    onClick={handleGenreFictionSelection}
                  />
                ))}
              </div>

              <div className={styles.titles}>Non-Fiction</div>
              <div className={styles.genres_container}>
                {selectedNonFictionGenres.map((genre, index) => (
                  <GenreCard
                    key={index}
                    genre={genre.genre}
                    genre_image={genre.genre_image}
                    selected={genre.selected}
                    onClick={handleNonFictionGenreFictionSelection}
                  />
                ))}
              </div>
            </div>
          </div>
        </div>
        <div className={styles.fotter}>
          <div
            className={`${styles.button}
                         ${
                           saveButtonState === 'success'
                             ? styles.successButton
                             : saveButtonState === 'failure'
                             ? styles.failureButton
                             : ''
                         }`}
            onClick={handleSavePreferanses}
          >
            <div type="submit" className={styles.button}>
              <div className={styles.textbutton}>{getButtonText()}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Preferences;

import React from 'react';
import { FaStar, FaStarHalfAlt, FaRegStar } from 'react-icons/fa';

// Convert similarity score (0.1-0.5) to a 1-5 star rating
export function convertToStarRating(similarity) {
  const clampedSimilarity = Math.max(0.1, Math.min(0.5, similarity));
  return 1 + ((clampedSimilarity - 0.1) / (0.5 - 0.1)) * 4;
}

// Component to display all genres' taste scores with stars
export function AllTasteScores({ tasteMatch, bookTasteInfo }) {
  if (tasteMatch.length === 0) {
    return '';
  }

  return (
    <div style={{ marginTop: '5px' }}>
      {tasteMatch.map(([genre, score], index) => {
        const starRating = convertToStarRating(score);
        const fullStars = Math.floor(starRating);
        const halfStar = starRating % 1 >= 0.5;
        const emptyStars = 5 - fullStars - (halfStar ? 1 : 0);

        let response = '';

        // Find the corresponding response
        if (bookTasteInfo && bookTasteInfo.length > 0) {
          const bookTasteItem = bookTasteInfo.find(
            (item) => item.genre === genre
          );
          response = bookTasteItem ? bookTasteItem.response : '';
        }

        return (
          <div key={`genre-${index}`} style={{ marginBottom: '2px' }}>
            <div
              style={{
                fontFamily: 'Lato',
                fontSize: '14px',
                fontWeight: 'bold',
                display: 'flex',
                alignItems: 'center',
                gap: '2px',
              }}
            >
              {genre}
              {/* Full stars */}
              {Array(fullStars)
                .fill(<FaStar color="#1CEAA0" size="13" />)
                .map((star, i) => (
                  <span key={`full-${index}-${i}`}>{star}</span>
                ))}
              {/* Half star */}
              {halfStar && (
                <span key={`half-${index}-${1}`}>
                  <FaStarHalfAlt color="#1CEAA0" size="13" />
                </span>
              )}
              {/* Empty stars */}
              {Array(emptyStars)
                .fill(<FaRegStar color="#1CEAA0" size="13" />)
                .map((star, i) => (
                  <span key={`empty-${index}-${i}`}>{star}</span>
                ))}
            </div>

            <div>{response}</div>
          </div>
        );
      })}
    </div>
  );
}

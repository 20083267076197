import styles from './AboutBook.module.css';
import dislike from '../../../icons/icondusLuke.svg';
import like from '../../../icons/iconlike.svg';
import ReaderViewNotes from './ReaderViewNotes';
import x from '../../../icons/vuesax/outline/addx.svg';
import Pbook from './Pbook';
import { FaEdit, FaTrash } from 'react-icons/fa';
import { renderCenteredAlert } from '../../../components/CenteredAlert';

import { useRecoilState } from 'recoil';
import {
  manuscriptInfoListAtom,
  goToApproveState,
  goToRejectState,
  authorInfoState,
  synopsisState,
  bookTasteInfoState,
  initialHighlightState,
  loadingStateCompare,
  most_similar_bookAtom,
  afterGetBookChapter,
  currentManuscriptAtom,
  clickedManuscriptAtom,
  userInfoState,
  keywordsState,
  manualLoginState,
  scoreDataAtom,
  chapterNamesAtom,
  contentAtom,
  notesAtom,
  readerViewState,
} from '../../../state';
import { useEffect, useState, useRef } from 'react';
import file from '../../../icons/iconfile.svg';
import { useNavigate } from 'react-router-dom';
import React from 'react';
// import PDFGenerator from './PDFGenerator';
import { PDFDocument, rgb, StandardFonts } from 'pdf-lib';
import Status from '../Status';
import Approve from './Approve';
import Reject from './Reject';
import useApiUtils from '../../../useApiUtils';
import SocialCrd from './SocialCrd';
import CircularProgress from '@mui/material/CircularProgress';
import expend_score from '../../../icons/expend_score.svg';
import despend from '../../../icons/despend.svg';
import AvatarCostom from '../../../UI/AvatarCostom';
import ScoreDisplay from '../../../UI/ScoreDisplay';
import { FaTimes, FaRegClosedCaptioning, FaRegTrashAlt } from 'react-icons/fa';

import Avatar from '@mui/material/Avatar';

import { v4 as uuidv4 } from 'uuid';

import LinkifyIt from 'linkify-it';

import { AllTasteScores } from '../AllTasteScores';

import Markdown from 'markdown-to-jsx';

// Create a linkify instance
const linkify = new LinkifyIt();

linkify.add('@', {
  validate: function (text, pos, self) {
    const tail = text.slice(pos);

    if (!self.re.twitter) {
      self.re.twitter = new RegExp(
        '^([a-zA-Z0-9_]){1,15}(?!_)(?=$|' + self.re.src_ZPCc + ')'
      );
    }
    if (self.re.twitter.test(tail)) {
      // Linkifier allows punctuation chars before prefix,
      // but we additionally disable `@` ("@@mention" is invalid)
      if (pos >= 2 && tail[pos - 2] === '@') {
        return false;
      }
      return tail.match(self.re.twitter)[0].length;
    }
    return 0;
  },
  normalize: function (match) {
    match.url = 'https://twitter.com/' + match.url.replace(/^@/, '');
  },
});

export const generateCorrelationId = () => uuidv4();

const AboutBook = (props) => {
  const [inithighlight, setInithighlight] = useRecoilState(
    initialHighlightState
  );
  const [userInfo, setUserInfo] = useRecoilState(userInfoState); // Update the path
  const [manualLogin, setManualLogin] = useRecoilState(manualLoginState);
  const [readerView, setReaderView] = useRecoilState(readerViewState);
  const [newReaderViewContent, setNewReaderViewContent] = useState('');

  const [clickedManuscript, setClickedManuscript] = useRecoilState(
    clickedManuscriptAtom
  );
  const navigate = useNavigate();
  const [navigateToReview, setNavigateToReview] = useState(false);
  const [currentManuscript, setCurrentManuscript] = useRecoilState(
    currentManuscriptAtom
  );
  const [most_similar_book, setMost_similar_book] = useRecoilState(
    most_similar_bookAtom
  );

  const [showApprove, setShowApprove] = useState(false);
  const [showReject, setShowReject] = useState(false);
  const [afterGetBookChapte, setAfterGetBookChapte] =
    useRecoilState(afterGetBookChapter);
  const [loading, setLoading] = useRecoilState(loadingStateCompare); //for the books

  const [userRejected, setUserRejected] = useState(false);
  const [userApproved, setUserApproved] = useState(false);
  const [authorInfo, setAuthorInfo] = useRecoilState(authorInfoState);
  const [synopsis, setSynopsis] = useRecoilState(synopsisState);
  const [bookTasteInfo, setBookTasteInfo] = useRecoilState(bookTasteInfoState);
  const [hoveredCardIndex, setHoveredCardIndex] = useState(null);

  const [filteredScoreData, setFilteredScoreData] = useState({});

  const [scoreData, setScoreData] = useRecoilState(scoreDataAtom);
  const [saveButtonState, setSaveButtonState] = useState('normal'); // 'normal', 'saving', 'success', 'failure'
  const [manuscriptInfoList, setManuscriptInfoList] = useRecoilState(
    manuscriptInfoListAtom
  );
  const [editingNote, setEditingNote] = useState(null);
  const [updatedContent, setUpdatedContent] = useState('');

  const [goToApprove, setGoToApprove] = useRecoilState(goToApproveState);
  const [goToReject, setGoToReject] = useRecoilState(goToRejectState);

  const [expanded, setExpanded] = useState({});

  const toggleExpand = (key) => {
    console.log('expanded', expanded);

    setExpanded({ ...expanded, [key]: !expanded[key] });
  };

  const toggleAllExpand = () => {
    console.log('expanded', expanded);
    const allExpanded = Object.values(expanded).every((value) => value); // Check if all items are currently expanded
    const newExpandedState = Object.keys(expanded).reduce((acc, key) => {
      acc[key] = !allExpanded;
      return acc;
    }, {});
    console.log('newExpandedState', newExpandedState);
    setExpanded(newExpandedState);
  };

  useEffect(() => {
    // // setClickedManuscript to be the element in  manuscriptList with the book_id of the old clickedManuscript book_id just to update the info inside
    const clickedManuscriptBookId = clickedManuscript.book_id;
    let index = 0;
    while (manuscriptInfoList[index].book_id !== clickedManuscriptBookId) {
      index++;
    }
    // if there is index that match the book_id update the clickedManuscript to be the element in the manuscriptInfoList with the same book_id
    if (index < manuscriptInfoList.length) {
      setClickedManuscript(manuscriptInfoList[index]);
      setCurrentManuscript({ bookId: manuscriptInfoList[index].book_id });
    }
  }, [manuscriptInfoList]);

  useEffect(() => {
    console.log('scoreData', scoreData);

    if (scoreData && scoreData.parameters_items) {
      const initialExpandedState = Object.keys(
        scoreData.parameters_items
      ).reduce((acc, key) => {
        acc[key] = false;
        return acc;
      }, {});

      setExpanded(initialExpandedState);
      console.log('initialExpandedState', initialExpandedState);
    }
  }, [scoreData]);

  const handleError = (e) => {
    e.target.style.visibility = 'hidden';
  };

  const {
    getManuscriptsInfo,
    initialgetBookChapter,
    get_book_scores,
    getbookgenrekeywords,
    fetchReaderViewNotes,
    manageReaderViewNote,
    processResponse,
    handleCompareApp,
    get_conversation_history,
    // Add other functions from the hook if needed
  } = useApiUtils();

  //i want to detect click outside of the deletebox
  const aboutbookboxRef = useRef(null);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        aboutbookboxRef.current &&
        !aboutbookboxRef.current.contains(event.target)
      ) {
        props.return(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [aboutbookboxRef]);

  //useeefect, if go to approve or reject, then show the approve or reject
  useEffect(() => {
    if (goToApprove) {
      setShowApprove(true);
      setGoToApprove(false);
    }
    if (goToReject) {
      setShowReject(true);
      setGoToReject(false);
    }
  }, [goToApprove, goToReject]);

  const handleApprove = (ans) => {
    setShowApprove(ans);
  };

  const handleReject = (ans) => {
    setShowReject(ans);
  };

  const handleClickApprove = () => {
    setShowApprove(true);
  };

  const handleClickReject = () => {
    console.log('can reject', clickedManuscript);
    setShowReject(true);
  };

  const handleNavigation = () => {
    const bokId = clickedManuscript.book_id;
    initialgetBookChapter(clickedManuscript.book_id);
    get_conversation_history(clickedManuscript.book_id);
    navigate(`/review/${bokId}`);
    setNavigateToReview(true);
  };

  const handleNavigationCompare = () => {
    const bokId = clickedManuscript.book_id;
    getbookgenrekeywords(clickedManuscript.book_id);
    handleCompareApp(null, null, clickedManuscript.book_id, null);
    navigate(`/compare/${bokId}`);
    setNavigateToReview(true);
  };

  const linkifyText = (text) => {
    const matches = linkify.match(text);
    if (!matches) {
      return text;
    }

    const elements = [];
    let lastIndex = 0;

    matches.forEach((match, index) => {
      // Add the text before the match as a regular text node
      if (match.index > lastIndex) {
        elements.push(text.slice(lastIndex, match.index));
      }

      // Add the matched text as a link
      elements.push(
        <a
          key={index}
          href={match.url}
          target="_blank"
          rel="noopener noreferrer"
          className={styles.linkifyText}
        >
          {match.text}
        </a>
      );

      lastIndex = match.lastIndex;
    });

    // Add any remaining text after the last match
    if (lastIndex < text.length) {
      elements.push(text.slice(lastIndex));
    }

    return elements;
  };

  //   ///////////Review Page Init///////////////
  const [chapterNames, setChapterNames] = useRecoilState(chapterNamesAtom);
  const [content, setContent] = useRecoilState(contentAtom);
  const [notes, setNotes] = useRecoilState(notesAtom);

  ///////download synopsis /////////

  const REACT_APP_BUILD_ENV =
    process.env.REACT_APP_BUILD_ENV || 'REACT_APP_BUILD_ENV-build_env';
  const REACT_APP_API_ID =
    process.env.REACT_APP_API_ID || 'REACT_APP_BUILD_ENV2-build_env';

  const downloadBookSynopsis = async () => {
    if (saveButtonState === 'loading') {
      return;
    }
    setSaveButtonState('loading');
    const requestPayload = {
      book_id: currentManuscript.bookId,
    };
    const baseURL = process.env.REACT_APP_BASE_URL || 'http://127.0.0.1:8000';
    const correlationId = generateCorrelationId();

    try {
      const response = await fetch(
        `${baseURL}/api/manuscript/download_synopsis`,
        {
          method: 'POST',
          body: JSON.stringify(requestPayload),
          headers: {
            'Content-Type': 'application/json',
            Authorization: `${localStorage.getItem('id_token')}`,
            'Correlation-ID': correlationId,
          },
        }
      );

      if (!response.ok) {
        // alert('Something went wrong! Please try again later.');
        renderCenteredAlert('Something went wrong! Please try again later.');
        setSaveButtonState('normal');
      }

      // Convert the response to a blob (binary large object) which represents the PDF
      const blob = await response.blob();

      // Create a link element to trigger the download
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = `${clickedManuscript.manuscript_name} Synopsis.pdf`;

      // Append the link to the body
      document.body.appendChild(link);

      // Trigger the download by simulating a click
      link.click();

      // Clean up by removing the link from the document
      document.body.removeChild(link);

      setSaveButtonState('normal');
    } catch (error) {
      console.error('Error downloading the synopsis:', error);
      setSaveButtonState('error');
    }
  };

  const truncateText = (text, maxWidth, font, fontSize, suffix) => {
    let truncated = text;
    let width = font.widthOfTextAtSize(truncated + suffix, fontSize);

    if (width <= maxWidth) {
      return truncated + suffix;
    }

    while (width > maxWidth && truncated.length > 0) {
      truncated = truncated.slice(0, -1);
      width = font.widthOfTextAtSize(truncated + '...' + suffix, fontSize);
    }

    return truncated + '...' + suffix;
  };

  const getButtonText = () => {
    switch (saveButtonState) {
      case 'saving':
        return 'Saving...';
      case 'success':
        return 'Saved Successfully';
      case 'failure':
        return 'Failed to Save';
      case 'loading':
        return <CircularProgress size={15} color="inherit" />;
      default:
        return 'Download Synopsis';
    }
  };

  const handleNavigationPreferencess = () => {
    navigate('/user-profile');
  };

  let backgroundColor = '#ded4d4';
  const handelbutton = () => {
    props.return(false);
  };

  if (!clickedManuscript) {
    return null; // Return null when clickedManuscript is null
  }

  const handleCardHover = (index) => {
    // Set the currently hovered card index
    if (hoveredCardIndex !== null) {
      return;
    }
    setHoveredCardIndex(index);
  };

  // Function to handle mouse leave events
  const handleCardLeave = () => {
    // Clear the currently hovered card index when mouse leaves
    setHoveredCardIndex(null);
  };

  // Utility function to format property names
  function formatPropertyName(propertyName) {
    return propertyName.charAt(0).toLowerCase() + propertyName.slice(1);
  }

 
  function openUrlInNewTab(url) {
    window.open(url, '_blank');
  }

  function interpretAIScore(score) {
    if (isNaN(score)) return 'Not Calculated';

    if (score > 90) {
      return 'Almost definitely';
    } else if (score >= 75) {
      return 'Very likely';
    } else if (score >= 60) {
      return 'Likely';
    } else if (score >= 45) {
      return 'Unlikely';
    } else {
      return 'Highly unlikely';
    }
  }

  return (
    <div className={manualLogin ? styles.backdrop : styles.backdrop}>
      <div
        className={manualLogin ? styles.manualcontainer : styles.container}
        ref={aboutbookboxRef}
      >
        {showApprove && (
          //    const [userApproved, setUserApproved] = useState(false);
          <Approve
            handleApprove={handleApprove}
            setUserApproved={setUserApproved}
            userApproved={userApproved}
            return={props.return}
          />
          // </div>
        )}
        {showReject && (
          // <div className={styles.rejectContainer}>
          <Reject
            handleReject={handleReject}
            userRejected={userRejected}
            setUserRejected={setUserRejected}
            return={props.return}
          />
          // </div>
        )}
        {!showApprove && !showReject && (
          <>
            <div className={styles.header}>
              <div className={styles.bookInfo}>
                <div className={styles.bookInfoTitle}>
                  <div className={styles.imagePart}>
                    <img
                      // src="https://file.rendit.io/n/hgU4B16bRa1DFz3hfnqQ.png"
                      src={clickedManuscript.image}
                      className={styles.bookInfoImg}
                    />
                  </div>
                  <div className={styles.AboutBookText}>
                    <div className={styles.bookName}>
                      {clickedManuscript.manuscript_name}
                    </div>

                    {clickedManuscript.author_name &&
                      clickedManuscript?.author_name !== null &&
                      clickedManuscript?.author_name !== ' ' && (
                        <div className={styles.by}>
                          By <strong>{clickedManuscript.author_name}</strong>
                        </div>
                      )}
                    <Status
                      value={clickedManuscript.status}
                      tc_star={clickedManuscript.tc_star}
                    />
                      <div className={styles.buttons}>
                        <div
                          className={styles.rejectB}
                          onClick={handleClickReject}
                        >
                          <img src={dislike} className={styles.disLike} />
                          <div className={styles.Rtext}>Decline</div>
                        </div>
                          <div
                            className={styles.ApproveB}
                            onClick={handleClickApprove}
                          >
                            <img src={like} className={styles.Like} />
                            <div className={styles.Atext}>Approve</div>
                          </div>
                      </div>
                  </div>
                </div>
                <div className={styles.tasteBoxContainer}>
                  <div className={styles.tasteBox}>
                    <div className={styles.tasteBoxTitle}>
                      Manuscript Readiness:{' '}
                      <ScoreDisplay
                        letterScore={scoreData.alphabetic_score}
                        numberScore={scoreData.numeric_score}
                      />{' '}
                    </div>

                    {clickedManuscript.taste_match.length > 0 && (
                      <>
                        <div className={styles.tasteBoxTitle}>Taste Match:</div>
                        <AllTasteScores
                          tasteMatch={clickedManuscript.taste_match}
                        />
                      </>
                    )}
                  </div>
                </div>
                <div className={styles.xbutton} onClick={handelbutton}>
                  {/* <img
                                        src={x}
                                        className={styles.x}
                                    /> */}
                  <FaTimes color="white" size="1em" />
                </div>
              </div>
            </div>
            <div className={styles.body}>
              <div className={styles.moreInfo}>
                <div className={styles.moreInfo2parts} id="Auto-declined">
                  {(clickedManuscript.status === 'Auto-declined' ||
                    scoreData?.title_score === 'Ineligible') &&
                    clickedManuscript.auto_decline_reason && (
                      <div className={styles.moreInfoPartautodecline}>
                        <div className={styles.firstTitle}>
                          {clickedManuscript.status === 'Auto-declined' ? (
                            <div className={styles.moreInfoTitle}>
                              Automatic Decline Triggers:{' '}
                            </div>
                          ) : (
                            <div className={styles.moreInfoTitle}>
                              {scoreData.title_score}
                              {' : '}
                            </div>
                          )}
                          <div
                            className={styles.shoeAll}
                            onClick={handleNavigationPreferencess}
                            id="NavigationPreferencess"
                          >
                            <div className={styles.shoeAllText}>
                              Update Preferences
                            </div>
                          </div>
                        </div>
                        <div
                          className={styles.infoText}
                          id="auto_decline_reason"
                        >
                          {clickedManuscript.auto_decline_reason &&
                            clickedManuscript.auto_decline_reason.map(
                              (reason, index) => (
                                <div
                                  key={index}
                                  className={styles.autoDeclineReason}
                                  id="autoDeclineReason"
                                >
                                  <div className={styles.TextLogLine}>
                                    <span className={styles.bulletPoint}>
                                      &bull;{' '}
                                    </span>
                                    {reason}
                                  </div>
                                </div>
                              )
                            )}
                        </div>
                      </div>
                    )}

                  <div className={styles.moreInfoPart1}>
                    <div className={styles.infoText}>
                      <div className={styles.inforow}>
                        <div className={styles.moreInfoTitle}>Log Line:</div>
                        <div className={styles.TextLogLine}>
                          {clickedManuscript.logline}
                        </div>
                      </div>
                      <div className={styles.inforow}>
                        <div className={styles.moreInfoTitle}>Genre:</div>
                        <div className={styles.TextLogLine}>
                          {clickedManuscript.genres &&
                            clickedManuscript.genres.map((genre, index) => (
                              <span className={styles.genre} key={index}>
                                {genre}
                                {index < clickedManuscript.genres.length - 1 &&
                                  ', '}
                              </span>
                            ))}
                        </div>
                      </div>
                      {/* if {clickedManuscript.archetype} is not none */}
                      {/* {clickedManuscript.archetype && (
                        <div className={styles.inforow}>
                          <div className={styles.moreInfoTitle}>Archetype:</div>
                          <div className={styles.TextLogLine}>
                            {clickedManuscript.archetype}
                          </div>
                        </div>
                      )} */}
                      <div className={styles.inforow}>
                        <div className={styles.moreInfoTitle}>Keywords:</div>
                        <div className={styles.TextLogLine}>
                          {clickedManuscript.keywords &&
                            clickedManuscript.keywords.map((keyword, index) => (
                              <span className={styles.keyword} key={index}>
                                {keyword}
                                {index <
                                  clickedManuscript.keywords.length - 1 && ', '}
                              </span>
                            ))}
                        </div>
                      </div>
                      {scoreData && scoreData.gen_ai_score && (
                        <div className={styles.inforow}>
                          <div className={styles.moreInfoTitle}>
                            Written by a Human:
                          </div>

                          <div className={styles.TextLogLine}>
                            {interpretAIScore(scoreData.gen_ai_score)}
                          </div>
                        </div>
                      )}

                      {scoreData?.parameters_items && (
                        <div className={styles.inforow}>
                          <div className={styles.moreInfoTitle}>Insights:</div>

                          <div className={styles.TextLogLine}>
                            {scoreData.lowest_score?.map((statement, index) => (
                              <div key={index}>• {statement}</div>
                            ))}
                          </div>
                        </div>
                      )}

                      {bookTasteInfo && (
                        <div className={styles.inforow}>
                          <div className={styles.moreInfoTitle}>
                            Taste Match:
                          </div>

                          <div className={styles.TextLogLine}>
                            <AllTasteScores
                              tasteMatch={clickedManuscript.taste_match}
                              bookTasteInfo={bookTasteInfo}
                            />
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <ReaderViewNotes
                    readerView={readerView}
                    manageReaderViewNote={manageReaderViewNote}
                    clickedManuscript={clickedManuscript}
                    setLoading={setLoading}
                  />
                  <div className={styles.moreInfoPart2}>
                    <div className={styles.firstTitle}>
                      <div className={styles.moreInfoTitle}>Comp Titles</div>
                      <div
                        className={styles.shoeAll}
                        onClick={handleNavigationCompare}
                      >
                        <div className={styles.shoeAllText}>Show All</div>
                      </div>
                    </div>
                    <div className={styles.similarBooks}>
                      {most_similar_book &&
                        most_similar_book.map((book, index) => (
                          <div
                            className={styles.similarBook}
                            key={index}
                            onClick={() => {
                              openUrlInNewTab(book.buy);
                            }}
                          >
                            <img
                              src={`https://${book.image.replace(
                                /^https?:\/\//,
                                ''
                              )}`}
                              className={styles.similarBookImg}
                              onError={handleError}
                            />

                            <div className={styles.similarBooktext}>
                              <div className={styles.similarBookName}>
                                {book.book_name}
                              </div>
                              <div className={styles.similarBookAuthor}>
                                By {book.author}
                              </div>
                            </div>
                          </div>
                        ))}
                    </div>
                  </div>

                  {authorInfo &&
                    authorInfo !== 'False' &&
                    clickedManuscript.author_name &&
                    authorInfo.author_info && (
                      <div className={styles.moreInfoPart2authorinfo}>
                        <div className={styles.autorinfoTitle}>
                          {/* {authorInfo?.images ? (
                            <Avatar
                              alt={
                                clickedManuscript.author_name ?? 'Unassigned'
                              }
                              src={authorInfo?.images}
                            />
                          ) : ( */}
                          <AvatarCostom
                            name={clickedManuscript.author_name ?? ''}
                          />
                          {/* )} */}
                          <div className={styles.autorinfoTitleText}>
                            {clickedManuscript.author_name ?? ''}
                          </div>
                        </div>
                        <div className={styles.autorinfoText}>
                          {linkifyText(authorInfo.author_info)}
                        </div>
                      </div>
                    )}

                  {synopsis && (
                    <div className={styles.moreInfoPart2authorinfo}>
                      <div className={styles.autorinfoText}>
                        <Markdown>{synopsis.text}</Markdown>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className={styles.footer}>
              <div className={styles.lowerBar}>
                <div
                  className={`${styles.ViewSynopsis}
                                ${
                                  saveButtonState === 'success'
                                    ? styles.successButton
                                    : saveButtonState === 'failure'
                                    ? styles.failureButton
                                    : ''
                                }`}
                  onClick={downloadBookSynopsis}
                >
                  <img className={styles.file} src={file} />
                  <div className={styles.ViewSynopsisText}>
                    {getButtonText()}
                  </div>
                </div>
                <div
                  className={manualLogin ? styles.manualreview : styles.review}
                  onClick={handleNavigation}
                >
                  <div className={styles.reviewText}>Review Manuscript</div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default AboutBook;

// const Gauge = ({ value = 50, size = 150 }) => {
//   const radius = size / 2 - 10; // Radius of the arc
//   const circumference = 2 * Math.PI * radius; // Circumference of the full circle
//   const arcLength = (value / 100) * circumference; // Length of the filled arc

//   return (
//     <div
//       className={styles.gaugeContainer}
//       style={{ width: size, height: size / 2 }}
//     >
//       <svg
//         width={size}
//         height={size / 2}
//         viewBox={`0 0 ${size} ${size / 2}`}
//         className={styles.gaugeSvg}
//       >
//         {/* Background Arc */}
//         <circle
//           cx={size / 2}
//           cy={size / 2}
//           r={radius}
//           fill="transparent"
//           stroke="#e0e0e0"
//           strokeWidth="10"
//           strokeDasharray={circumference}
//           strokeDashoffset={0}
//           transform={`rotate(-90 ${size / 2} ${size / 2})`}
//         />

//         {/* Filled Arc */}
//         <circle
//           cx={size / 2}
//           cy={size / 2}
//           r={radius}
//           fill="transparent"
//           stroke="url(#gradient)" // Gradient for the arc
//           strokeWidth="10"
//           strokeDasharray={circumference}
//           strokeDashoffset={circumference - arcLength}
//           transform={`rotate(-90 ${size / 2} ${size / 2})`}
//           className={styles.gaugeArc}
//         />

//         {/* Gradient Definition */}
//         <defs>
//           <linearGradient id="gradient" x1="0%" y1="0%" x2="100%" y2="0%">
//             <stop offset="0%" stopColor="#ff007f" />
//             <stop offset="100%" stopColor="#00c6ff" />
//           </linearGradient>
//         </defs>
//       </svg>

//       {/* Display the value in the center */}
//       <div className={styles.gaugeValue}>
//         <span>{value}%</span>
//       </div>
//     </div>
//   );
// };

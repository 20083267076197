import styles from './Wishlist.module.css';
import React, { useState, useEffect } from 'react';

import { useRecoilState } from 'recoil';
import { companyInfoState, userInfoState } from '../../state';

import { pink } from '@mui/material/colors';
import Checkbox from '@mui/material/Checkbox';
import expend_score from '../../icons/expend_score copy 2.svg';
import despend from '../../icons/despend copy 2.svg';
import Thriller from '../../icons/genreImage/MysteryThriller.svg';
import Mystery from '../../icons/genreImage/MysteryThriller.svg';
import HistoricalFiction from '../../icons/genreImage/HistoricalFiction.svg';
import Romance from '../../icons/genreImage/Romance.svg';
import Horror from '../../icons/genreImage/Horror.svg';
import Fantasy from '../../icons/genreImage/ScifiFantasy.svg';
import SciFi from '../../icons/genreImage/genre/genre/architype/Rebirth.svg';
import Memoir from '../../icons/genreImage/Memoir.svg';
import YA from '../../icons/genreImage/genre/ya.svg';
import LiteraryFiction from '../../icons/genreImage/LiteraryFiction.svg';
import defaultbook from '../../icons/genreImage/defaultbook.svg';
import CircularProgress from '@mui/material/CircularProgress';

import useApiUtils from '../../useApiUtils';

import { useNavigate } from 'react-router-dom';
import {
  renderCenteredAlert,
  renderCenteredInfo,
} from '../../components/CenteredAlert';

import Business from '../../icons/genreImage/genre/Business.svg';
import Educational from '../../icons/genreImage/genre/Educational.svg';
import fantasy from '../../icons/genreImage/genre/fantasy.svg';
import GamesGaming from '../../icons/genreImage/genre/image 15.svg';
import historic from '../../icons/genreImage/genre/historic.svg';
import History from '../../icons/genreImage/genre/architype/Rags to Riches.svg';
import HomeLifestyle from '../../icons/genreImage/genre/Home & Lifestyle.svg';
import horror from '../../icons/genreImage/genre/horror.svg';
import Memoir1 from '../../icons/genreImage/genre/genre/genre/genre/fiction.svg';
import NatureClimate from '../../icons/genreImage/genre/Nature & Climate.svg';
import Paranormalromance from '../../icons/genreImage/genre/genre/adult.svg';
import Parenting from '../../icons/genreImage/genre/Parenting.svg';
import PeriodRomance from '../../icons/genreImage/genre/Period Romance.svg';
import PopularScience from '../../icons/genreImage/genre/Popular Science.svg';
import Religion from '../../icons/genreImage/genre/Religion.svg';
import romance from '../../icons/genreImage/genre/romance.svg';
import Selfhelp from '../../icons/genreImage/genre/Self help.svg';
import SportsHobbies from '../../icons/genreImage/genre/Sport & Hobbies.svg';
import Travel from '../../icons/genreImage/genre/Travel.svg';
import mysteryCrime from '../../icons/genreImage/Mystery:Crime.svg';
import ContemporaryRomance from '../../icons/genreImage/genre/genre/romance.svg';
import Academic from '../../icons/genreImage/genre/Academic.svg';
import generalfiction from '../../icons/genreImage/genre/genre/architype/Comedy.svg';
import generalnonfiction from '../../icons/genreImage/genre/genre/architype/Tragedy.svg';
import Collectibles from '../../icons/genreImage/genre/genre/Collectibles.svg';

import Accordion from '@mui/material/Accordion';
import AccordionActions from '@mui/material/AccordionActions';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '../../icons/expand_more.png';
import DeleteKeywordIcon from '../../icons/delete-keyword.png';
import Button from '@mui/material/Button';
import WordCloudComponent from './WordCloudComponent';
import ThesisText from './ThesisText';
import SwButton from '../storywise/SwButton';
import { set } from 'date-fns';

import { fictionGenreList, nonFictionGenreList } from '../../genreLists';

const accordionStyles = {
  marginBottom: '20px',
  border: 'solid 1px #dddee0',
  boxShadow: 'none',
  borderRadius: '12px',
  borderTopLeftRadius: '12px',
  '&:before': {
    display: 'none',
  },
  '&:first-of-type': {
    borderTopLeftRadius: '12px',
    borderTopRightRadius: '12px',
  },
  '&:last-of-type': {
    borderBottomLeftRadius: '12px',
    borderBottomRightRadius: '12px',
  },
};

const WishlistAccordion = (props) => {
  const [originalGenreKeywords, setOriginalGenreKeywords] = useState({});
  const [originalWishlistTheses, setOriginalWishlistTheses] = useState({});

  const [loadingWislist, setLoadingWishlist] = useState(true);

  const [userInfo, setUserInfo] = useRecoilState(userInfoState); // Update the path

  const [saveButtonStates, setSaveButtonStates] = useState({});

  const [genButtonStates, setGenButtonStates] = useState({});

  const getSaveButtonState = (genre) => {
    return saveButtonStates[genre] || 'normal';
  };

  const updateSaveButtonState = (genre, newState) => {
    setSaveButtonStates((prevStates) => ({
      ...prevStates,
      [genre]: newState,
    }));
  };

  const getGenButtonState = (genre) => {
    return genButtonStates[genre] || 'normal';
  };

  const updateGenButtonState = (genre, newState) => {
    setGenButtonStates((prevStates) => ({
      ...prevStates,
      [genre]: newState,
    }));
  };

  const [selectedFictionGenres, setSelectedFictionGenres] =
    useState(fictionGenreList);
  const [selectedNonFictionGenres, setSelectedNonFictionGenres] =
    useState(nonFictionGenreList);

  // State to hold genres and their associated keywords
  const [genreKeywords, setGenreKeywords] = useState({});

  const [wishlistTheses, setWishlistTheses] = useState({});

  const navigate = useNavigate();

  const [expanded, setExpanded] = useState({});

  const {
    getWishlist,
    setWishlist,
    generateThesis,
    // Add other functions from the hook if needed
  } = useApiUtils();

  useEffect(() => {
    // Fetch wishlist on component mount
    const loadWishlist = async () => {
      const wishlistData = await getWishlist();

      if (wishlistData) {
        // Initialize maps for genres, theses, and keywords
        const keywordsMap = {};
        const thesesMap = {};

        // Populate the maps based on the returned wishlistData
        if (Array.isArray(wishlistData)) {
          wishlistData.forEach((genreWishlist) => {
            const { genre, thesis, phrases } = genreWishlist;

            // Store thesis for each genre
            thesesMap[genre] = thesis;

            // Store phrases for each genre
            keywordsMap[genre] = phrases || []; // Default to empty array if no phrases
          });
        }

        // Update state with the populated maps
        setWishlistTheses(thesesMap);
        setGenreKeywords(keywordsMap);
      }

      setLoadingWishlist(false);
    };

    loadWishlist();
  }, []);

  useEffect(() => {
    if (userInfo && userInfo.Preferences) {
      if (userInfo.Preferences.genres) {
        const newGenres = selectedFictionGenres.map((genre) => {
          if (userInfo.Preferences.genres.includes(genre.genre)) {
            return {
              ...genre,
              selected: true,
            };
          }
          return genre;
        });
        setSelectedFictionGenres(newGenres);
      }
      // do it for non fiction
      if (userInfo.Preferences.non_fiction_genres) {
        const newGenres = selectedNonFictionGenres.map((genre) => {
          if (userInfo.Preferences.non_fiction_genres.includes(genre.genre)) {
            return {
              ...genre,
              selected: true,
            };
          }
          return genre;
        });
        setSelectedNonFictionGenres(newGenres);
      }
    }
  }, [userInfo]);

  const handleRelevantSelectioncategoty = (category) => {};

  const handlePromisingSelectioncategoty = (category) => {};

  // we only want to save the currently edited genre so we need to cherry pick it
  const removeAllKeywordsExceptGenre = (genreToKeep) => {
    return { [genreToKeep]: genreKeywords[genreToKeep] || [] };
  };

  const removeAllThesesExceptGenre = (genreToKeep) => {
    return { [genreToKeep]: wishlistTheses[genreToKeep] || [] };
  };

  const handleSaveWishlist = async (genre) => {
    if (getSaveButtonState(genre) === 'loading') {
      return;
    }

    updateSaveButtonState(genre, 'loading');

    const specificGenreKeywords = removeAllKeywordsExceptGenre(genre);
    const specificGenreThesis = removeAllThesesExceptGenre(genre);

    const success = await setWishlist(
      specificGenreKeywords,
      specificGenreThesis
    );

    if (!success) {
      updateSaveButtonState(genre, 'failure');
    } else {
      renderCenteredInfo(genre + ' saved successfully');
      updateSaveButtonState(genre, 'success');
    }
  };

  const getButtonText = (genre) => {
    switch (getSaveButtonState(genre)) {
      case 'loading':
        return <CircularProgress size={15} color="inherit" />;
      default:
        return 'Save and Close';
    }
  };

  const getGenButtonText = (genre) => {
    switch (getGenButtonState(genre)) {
      case 'loading':
        return <CircularProgress size={15} color="inherit" />;
      default:
        return 'Generate Taste Profile';
    }
  };

  // Function to add a keyword for a genre
  const addKeyword = (genre, keyword) => {
    console.log('addKeyword', genre, keyword);
    setGenreKeywords((prevState) => ({
      ...prevState,
      [genre]: prevState[genre]
        ? [...prevState[genre], keyword] // Add keyword if genre already exists
        : [keyword], // Create new array if genre doesn't exist yet
    }));
  };

  // Function to remove a keyword for a genre
  const removeKeyword = (genre, keyword) => {
    setGenreKeywords((prevState) => ({
      ...prevState,
      [genre]: prevState[genre]
        ? prevState[genre].filter((kw) => kw !== keyword) // Remove keyword from array
        : [], // Ensure empty if genre doesn't exist
    }));
  };

  const setThesisForGenre = (genre, thesis) => {
    // Update the thesis for the given genre in the wishlistTheses state
    setWishlistTheses((prevTheses) => ({
      ...prevTheses,
      [genre]: thesis, // Set the new thesis for the given genre
    }));
  };

  const callGenerateThesis = async (genre, keywords) => {
    updateGenButtonState(genre, 'loading');

    const result = await generateThesis(genre, keywords);
    if (result.success) {
      setThesisForGenre(genre, result.thesis);
    } else {
      setThesisForGenre(genre, result.error);
    }

    updateGenButtonState(genre, 'success');
  };

  const handleEditClick = (genre) => {
    console.log('handleEditClick', genre);
    setExpanded((prevExpanded) => ({
      ...prevExpanded,
      [genre]: true,
    }));
    // Save the current state of keywords and theses as the original state
    setOriginalGenreKeywords((prevState) => ({
      ...prevState,
      [genre]: genreKeywords[genre] || [],
    }));
    setOriginalWishlistTheses((prevState) => ({
      ...prevState,
      [genre]: wishlistTheses[genre] || '',
    }));
  };

  const handleCancel = (genre) => {
    console.log('handleCancel', genre);

    // Revert the changes
    setGenreKeywords((prevKeywords) => ({
      ...prevKeywords,
      [genre]: originalGenreKeywords[genre] || [],
    }));

    setWishlistTheses((prevTheses) => ({
      ...prevTheses,
      [genre]: originalWishlistTheses[genre] || '',
    }));

    // Close the accordion
    setExpanded((prevExpanded) => ({
      ...prevExpanded,
      [genre]: false,
    }));
  };

  const handleSaveAndClose = async (genre) => {
    console.log('handleSaveAndClose', genre);

    await handleSaveWishlist(genre);
    // Perform save logic here, such as an API call.
    // After a successful save, close the accordion.
    setExpanded((prevExpanded) => ({
      ...prevExpanded,
      [genre]: false,
    }));
  };

  return (
    <div>
      {[...selectedFictionGenres, ...selectedNonFictionGenres].filter(
        (genre) => genre.selected === true
      ).length === 0 ? ( // Only show selected genres
        // Fallback UI if no genres are selected
        <div className={styles.noGenresSelected}>
          <p>No genres selected. Please choose some genres first.</p>
        </div>
      ) : (
        // Render the accordions for the selected genres
        [...selectedFictionGenres, ...selectedNonFictionGenres]
          .filter((genre) => genre.selected === true) // only show genres the user selected on the previous screen
          .map((genre, index) => {
            const genreName = genre.genre;

            const currentKeywords = genreKeywords[genreName] || [];
            const currentThesis = wishlistTheses[genreName] || '';

            return (
              <Accordion
                key={genreName}
                sx={accordionStyles}
                expanded={expanded[genreName] || false}
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                <AccordionSummary
                  //   expandIcon={
                  //     <img src={ExpandMoreIcon} alt="Expand More" />
                  //   }

                  aria-controls="panel1-content"
                  key={`panel${index}-header`}
                  className={styles.accordionSummary}
                  sx={{
                    '& .MuiAccordionSummary-content.Mui-expanded': {
                      marginBottom: '0',
                    },
                    backgroundColor: 'transparent',
                    '&:hover': {
                      backgroundColor: 'transparent',
                    },
                    '&.Mui-expanded': {
                      backgroundColor: 'transparent',
                    },
                    '&.Mui-focused': {
                      backgroundColor: 'transparent',
                    },
                    '&.MuiAccordionSummary-root.Mui-expanded': {
                      backgroundColor: 'transparent',
                    },

                    cursor: 'default',
                    '& .MuiTypography-root': {
                      cursor: 'default', // Applies to Typography children
                    },
                    '& .MuiAccordionSummary-content': {
                      cursor: 'default', // Apply to the content inside SummaryContainer
                    },
                    '& .MuiAccordionSummary-expandIconWrapper': {
                      cursor: 'default', // Apply to the expand icon wrapper inside SummaryContainer
                    },
                  }}
                  classes={{
                    expanded: styles.expandedAccordion, // Apply your custom class
                  }}
                >
                  <div className={styles.summaryContainer}>
                    <div>
                      <img
                        src={genre.genre_image}
                        alt={genre}
                        className={styles.genreImage}
                      />
                    </div>
                    <div className={styles.genreName}>
                      {genreName}
                      {/* {genreName} ({currentKeywords.length}) */}
                    </div>
                    <div className={`${styles.wishlistParagraphWrapper}`}>
                      <ThesisText
                        loadingWislist={
                          loadingWislist ||
                          getGenButtonState(genreName) === 'loading'
                        }
                        text={currentThesis}
                        disabled={!expanded[genreName]}
                        onChangeText={(text) => {
                          setThesisForGenre(genreName, text);
                        }}
                      />
                    </div>
                    <div>
                      {!expanded[genreName] && !loadingWislist && (
                        <SwButton
                          color="primary"
                          onClick={() => handleEditClick(genreName)}
                        >
                          Edit
                        </SwButton>
                      )}
                    </div>
                  </div>
                </AccordionSummary>
                <AccordionDetails>
                  <div className={styles.subHeading}>Chosen Phrases:</div>
                  <div className={styles.selectedKeywordWrapper}>
                    {currentKeywords.map((keyword) => {
                      return (
                        <div
                          className={styles.selectedKeyword}
                          key={keyword}
                          onClick={(e) => {
                            e.stopPropagation();
                          }}
                        >
                          <div>{keyword}</div>
                          <img
                            src={DeleteKeywordIcon}
                            className={styles.deleteKeywordImage}
                            alt="Delete"
                            onClick={(e) => {
                              e.stopPropagation();
                              removeKeyword(genreName, keyword);
                            }}
                          />
                        </div>
                      );
                    })}
                    {currentKeywords.length === 0 ? (
                      <div className={styles.noneText}>None</div>
                    ) : (
                      <SwButton
                        onClick={() => {
                          callGenerateThesis(genreName, currentKeywords);
                        }}
                      >
                        {getGenButtonText(genreName)}
                      </SwButton>
                    )}
                  </div>

                  <div className={styles.subHeading}>Phrases Bucket List:</div>
                  <WordCloudComponent
                    genre={genreName}
                    selectedKeywords={currentKeywords}
                    addKeyword={addKeyword}
                  />

                  <div className={styles.saveAndCloseButtonContainer}>
                    <SwButton onClick={() => handleSaveAndClose(genreName)}>
                      {getButtonText(genreName)}
                    </SwButton>

                    <SwButton
                      variant="secondary"
                      disabled={getSaveButtonState(genreName) === 'loading'}
                      onClick={() => handleCancel(genreName)}
                    >
                      Cancel
                    </SwButton>
                  </div>
                </AccordionDetails>
              </Accordion>
            );
          })
      )}
    </div>
  );
};
export default WishlistAccordion;

import React, { useState } from 'react';
import styles from './ThesisText.module.css';
import { TextField, Typography, Skeleton } from '@mui/material';

const emptyMessage =
  'No taste profile defined, so no taste match will be calculated.';

const ThesisText = (props) => {
  const [isEditing, setIsEditing] = useState(false);
  const { text, onChangeText } = props;

  const handleTextClick = () => {
    setIsEditing(true);
  };

  const handleTextChange = (event) => {
    onChangeText(event.target.value); // Call the parent function to update text
  };

  const handleBlur = () => {
    setIsEditing(false);
  };

  if (props.loadingWislist === true) {
    return <Skeleton variant="text" width={'100%'} height={30} />;
  }

  return (
    <div>
      {isEditing && !props.disabled ? (
        <TextField
          variant="standard" // Adds underline style
          multiline // Allows multiline text
          value={text}
          onChange={handleTextChange}
          onBlur={handleBlur} // Exit edit mode when focus is lost
          autoFocus
          fullWidth
          sx={{
            '& .MuiInput-underline:after': {
              borderBottomColor: '#1ceaa0', // Focused underline color
            },
          }}
        />
      ) : (
        <Typography
          onClick={handleTextClick}
          sx={{ cursor: 'pointer', fontFamily: 'Lato' }} // Changes cursor to indicate editability
        >
          {text.length === 0 ? (
            <span className={styles.emptyMessage}>{emptyMessage}</span>
          ) : (
            text
          )}
        </Typography>
      )}
    </div>
  );
};

export default ThesisText;

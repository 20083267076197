import React from 'react';
// import { Auth } from 'aws-amplify';
import styles from './UserMenuDropdown.module.css'; // Import your CSS module
import Wishlist from './Wishlist';
import Preferences from './Preferences';
import ManegUsers from './ManegUsers';
import ManuscriptsUploads from './ManuscriptsUploads';
import EmailTemplate from './EmailTemplate';
import CompanyAutoDecline from './CompanyAutoDecline';
import Header from '../header/header';
import { useRecoilState } from 'recoil';
import {
  userRejected_ApprovedNoteState,
  initialHighlightState,
  most_similar_bookAtom,
  fileState,
  fileInfoState,
  idTokeneState,
  accessTokenState,
  notes_highlightsState,
  companyInfoState,
  numberOfUploadedFilesState,
  userInfoState,
  headerColumnsTranslationState,
  loadingmanuscriptState,
  pendingProcessingState,
  loadingStateCompare,
  currentManuscriptAtom,
  afterGetBookChapter,
  clickedManuscriptAtom,
  genresState,
  keywordsState,
  genresDataState,
  scoreDataAtom,
  chapterNamesAtom,
  selectedChapterAtom,
  contentAtom,
  notesAtom,
  manuscriptInfoListAtom,
  startPollingState,
  books_id_dwonloadState,
  wishlistMenuState,
  preferencesState,
  emailTemplateState,
  CompanyAutoDeclineState,
  manuscriptsUploadsState,
  UserRightsandRolesState,
  profileState,
} from '../../state';
import IconSelected from '../../icons/side_manu 2/Icon.svg';
import Icon1 from '../../icons/side_manu 2/Icon1.svg';
import Icon2 from '../../icons/side_manu 2/Icon2.svg';
import Icon3 from '../../icons/side_manu 2/Icon3.svg';
import Selectedemail from '../../icons/side_manu 2/Selectedemail.svg';
import selectedpre from '../../icons/side_manu 2/selectedpre.svg';
import adduser from '../../icons/side_manu 2/adduser.svg';
import upload from '../../icons/side_manu 2/upload.svg';
import pre from '../../icons/side_manu 2/pre.svg';
import logout from '../../icons/side_manu 2/logout.svg';
import Avatar from '@mui/material/Avatar';
import { useNavigate } from 'react-router-dom';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import { Tabs, Tab } from '@mui/material';
import {
  PiHeart,
  PiListHeart,
  PiEnvelopeLight,
  PiGear,
  PiFileArrowUp,
  PiUsersLight,
} from 'react-icons/pi';

import { useState, useEffect } from 'react';

import {
  CognitoUserPool,
  CognitoUser,
  AuthenticationDetails,
} from 'amazon-cognito-identity-js';
import { set } from 'date-fns';

const REACT_APP_BUILD_ENV =
  process.env.REACT_APP_BUILD_ENV || 'REACT_APP_BUILD_ENV-build_env';
const REACT_APP_API_ID =
  process.env.REACT_APP_API_ID || 'REACT_APP_BUILD_ENV2-build_env';

const poolData = {
  UserPoolId: process.env.REACT_APP_USER_POOL_ID || 'USER_POOL_ID-build_env',
  ClientId:
    process.env.REACT_APP_CLIENT_ID_USER_POOL ||
    'CLIENT_ID_USER_POOL-build_env',
};

const userPool = new CognitoUserPool(poolData);

const UserMenuDropdown = ({ user }) => {
  const [books_id_dwonload, setBooks_id_dwonload] = useRecoilState(
    books_id_dwonloadState
  ); // Update the path
  const [startPolling, setStartPolling] = useRecoilState(startPollingState); // Update the path
  const [userInfo, setUserInfo] = useRecoilState(userInfoState); // Update the path
  const [manuscriptInfoList, setManuscriptInfoList] = useRecoilState(
    manuscriptInfoListAtom
  );
  const [clickedManuscript, setClickedManuscript] = useRecoilState(
    clickedManuscriptAtom
  );
  const [currentManuscript, setCurrentManuscript] = useRecoilState(
    currentManuscriptAtom
  );
  const [headerColumnsTranslation, setHeaderColumnsTranslation] =
    useRecoilState(headerColumnsTranslationState);
  const [pendingProcessing, setPendingProcessing] = useRecoilState(
    pendingProcessingState
  );
  const [loadingState, setLoadingState] = useRecoilState(loadingStateCompare);
  const [loadingmanuscript, setLoadingmanuscript] = useRecoilState(
    loadingmanuscriptState
  );
  const [genresData, setGenresData] = useRecoilState(genresDataState);
  const [keywords, setKeywords] = useRecoilState(keywordsState);
  const [genres, setGenres] = useRecoilState(genresState);
  const [notes, setNotes] = useRecoilState(notesAtom);
  const [content, setContent] = useRecoilState(contentAtom);
  const [chapterNames, setChapterNames] = useRecoilState(chapterNamesAtom);
  const [scoreData, setScoreData] = useRecoilState(scoreDataAtom);
  const [file, setFile] = useRecoilState(fileState);
  const [fileInfo, setFileInfo] = useRecoilState(fileInfoState);
  const [idToken, setIdToken] = useRecoilState(idTokeneState);
  const [accessToken, setAccessToken] = useRecoilState(accessTokenState);
  const [notes_highlights, setNotes_highlights] = useRecoilState(
    notes_highlightsState
  );
  const [numberOfUploadedFiles, setNumberOfUploadedFiles] = useRecoilState(
    numberOfUploadedFilesState
  );
  const [most_similar_book, setMost_similar_book] = useRecoilState(
    most_similar_bookAtom
  );
  const [initialHighlight, setInitialHighlight] = useRecoilState(
    initialHighlightState
  );
  const [userRejected_ApprovedNote, setUserRejected_ApprovedNote] =
    useRecoilState(userRejected_ApprovedNoteState);
  const [selectedChapter, setSelectedChapter] =
    useRecoilState(selectedChapterAtom);
  const [companyInfo, setCompanyInfo] = useRecoilState(companyInfoState);

  //function to empty all the stares
  const emptyAllStates = () => {
    setUserInfo(null);
    setClickedManuscript([]);
    setCurrentManuscript([]);
    setHeaderColumnsTranslation([]);
    setPendingProcessing(0);
    setLoadingState(false);
    setLoadingmanuscript(false);
    setGenresData({});
    setKeywords([]);
    setGenres([]);
    setNotes([]);
    setManuscriptInfoList([]);
    setContent([]);
    setChapterNames([]);
    setScoreData({});
    setFile([]);
    setStartPolling(false);
    setSelectedChapter(0);
    setFileInfo([]);
    setIdToken(null);
    setAccessToken(null);
    setNotes_highlights([]);
    setNumberOfUploadedFiles(0);
    setMost_similar_book([]);
    setUserRejected_ApprovedNote([]);
    setBooks_id_dwonload([]);
    setCompanyInfo(null);
  };

  const logoutUser = () => {
    const cognitoUser = userPool.getCurrentUser();
    if (cognitoUser) {
      cognitoUser.signOut();
    }

    // Clear tokens and user-related data from sessionStorage
    localStorage.removeItem('access_token');
    localStorage.removeItem('access_token_expiration');
    localStorage.removeItem('id_token');
    localStorage.removeItem('id_token_expiration');
    localStorage.removeItem('refresh_token');
    localStorage.removeItem('userId');
    localStorage.removeItem('email');
    emptyAllStates();
    sessionStorage.clear();
    navigate('/'); // Use the appropriate route here
  };

  const navigate = useNavigate();

  const [preferences, setPreferences] = useRecoilState(preferencesState);
  const [wishlist, setWishlist] = useRecoilState(wishlistMenuState);
  const [emailTemplate, setEmailTemplate] = useRecoilState(emailTemplateState);
  const [companyAutoDecline, setCompanyAutoDecline] = useRecoilState(
    CompanyAutoDeclineState
  );
  const [manuscriptsUploads, setManuscriptsUploads] = useRecoilState(
    manuscriptsUploadsState
  );
  const [UserRightsandRoles, setUserRightsandRoles] = useRecoilState(
    UserRightsandRolesState
  );

  const [Profile, setProfile] = React.useState(false);

  const handleSignOut = async () => {};

  const handleCompanyAutoDecline = () => {
    setWishlist(false);
    setPreferences(false);
    setEmailTemplate(false);
    setManuscriptsUploads(false);
    setUserRightsandRoles(false);
    setProfile(false);
    setCompanyAutoDecline(true);
    handleBackDrop('CompanyAutoDecline');
  };

  const handleEmailTemplate = () => {
    setWishlist(false);
    setPreferences(false);
    setEmailTemplate(true);
    setManuscriptsUploads(false);
    setUserRightsandRoles(false);
    setProfile(false);
    setCompanyAutoDecline(false);
    handleBackDrop('emailTemplate');
  };

  const handleUploads = () => {
    setWishlist(false);
    setPreferences(false);
    setEmailTemplate(false);
    setManuscriptsUploads(true);
    setUserRightsandRoles(false);
    setProfile(false);
    setCompanyAutoDecline(false);
    handleBackDrop('manuscriptsUploads');
  };

  const handleUserRightsandRoles = () => {
    setWishlist(false);
    setPreferences(false);
    setEmailTemplate(false);
    setManuscriptsUploads(false);
    setUserRightsandRoles(true);
    setProfile(false);
    setCompanyAutoDecline(false);
    handleBackDrop('userRightsandRoles');
  };

  const handleProfile = () => {
    setWishlist(false);
    setPreferences(false);
    setEmailTemplate(false);
    setManuscriptsUploads(false);
    setUserRightsandRoles(false);
    setProfile(true);
    setCompanyAutoDecline(false);
    handleBackDrop('profile');
  };

  const handleWishlist = () => {
    console.log('setting wishlist menu item 4');
    setWishlist(true);
    setPreferences(false);
    setEmailTemplate(false);
    setManuscriptsUploads(false);
    setUserRightsandRoles(false);
    setProfile(false);
    setCompanyAutoDecline(false);
    handleBackDrop('wishlist');
  };

  const handlePreferences = () => {
    setWishlist(false);
    setPreferences(true);
    setEmailTemplate(false);
    setManuscriptsUploads(false);
    setUserRightsandRoles(false);
    setProfile(false);
    setCompanyAutoDecline(false);
    handleBackDrop('preferences');
  };

  const handleBackDrop = (openoption) => {
    //save openoption in the local storage
    localStorage.setItem('openoption', openoption);
  };

  const [selectedTab, setSelectedTab] = useState(1); // start off with Wishlist selected

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  //intial the state that in if there is openoption in the local storage
  React.useEffect(() => {
    if (localStorage.getItem('openoption')) {
      console.log(
        'found openoption in local storage:',
        localStorage.getItem('openoption')
      );
      switch (localStorage.getItem('openoption')) {
        case 'preferences':
          setSelectedTab(0);
          setWishlist(false);
          setPreferences(true);
          setEmailTemplate(false);
          setCompanyAutoDecline(false);
          setManuscriptsUploads(false);
          setUserRightsandRoles(false);
          setProfile(false);
          break;
        case 'wishlist':
          console.log('setting wishlist menu item 2');
          setSelectedTab(1);
          setWishlist(true);
          setPreferences(false);
          setEmailTemplate(false);
          setCompanyAutoDecline(false);
          setManuscriptsUploads(false);
          setUserRightsandRoles(false);
          setProfile(false);
          break;
        case 'emailTemplate':
          setSelectedTab(2);
          setWishlist(false);
          setPreferences(false);
          setEmailTemplate(true);
          setManuscriptsUploads(false);
          setUserRightsandRoles(false);
          setProfile(false);
          setCompanyAutoDecline(false);
          break;
        case 'manuscriptsUploads':
          setSelectedTab(4);
          setWishlist(false);
          setPreferences(false);
          setEmailTemplate(false);
          setManuscriptsUploads(true);
          setUserRightsandRoles(false);
          setProfile(false);
          setCompanyAutoDecline(false);
          break;
        case 'userRightsandRoles':
          setSelectedTab(5);
          setWishlist(false);
          setPreferences(false);
          setEmailTemplate(false);
          setManuscriptsUploads(false);
          setCompanyAutoDecline(false);
          setUserRightsandRoles(true);
          setProfile(false);
          handleBackDrop('userRightsandRoles');
          break;

        case 'CompanyAutoDecline':
          setSelectedTab(3);
          setWishlist(false);
          setPreferences(false);
          setEmailTemplate(false);
          setManuscriptsUploads(false);
          setCompanyAutoDecline(true);
          setUserRightsandRoles(false);
          setProfile(false);
          handleBackDrop('CompanyAutoDecline');
          break;
        default:
          break;
      }
    }
    //if non of them is true so set the wishlist to true
    else {
      console.log('setting wishlist menu item 3');
      setSelectedTab(1);
      setWishlist(true);
      setCompanyAutoDecline(false);
      setPreferences(false);
      setEmailTemplate(false);
      setManuscriptsUploads(false);
      setUserRightsandRoles(false);
      setProfile(false);
    }

    const storedState = localStorage.getItem('currentState');
    if (storedState) {
      handleUploads();
    }
  }, []);

  let name = '';
  let role = '';
  let firstLetter;
  let colorCode;
  if (userInfo) {
    firstLetter = getInitials(userInfo?.name);
    colorCode = generateColorCode(firstLetter); // Generate color code based on owner's name
    name = userInfo?.name;
    role = userInfo?.role;
    //if role ===\v\1 so Editor else if role ===\v\2 so Reviewer else if role ===\v\3 so Manager
    if (role === 'lvl1') {
      role = 'Editor';
    } else if (role === 'lvl2') {
      role = 'Manager';
    }
  } else {
    firstLetter = getInitials('NA');
    colorCode = generateColorCode(firstLetter); // Generate color code based on owner's name
  }

  function getInitials(name) {
    if (!name) {
      return 'NA';
    }
    const words = name.split(' ');
    if (words.length === 1) {
      const firstLetter = words[0].charAt(0).toUpperCase();
      // const secondLetter = words[0].charAt(1).toLowerCase();
      // return `${firstLetter}${secondLetter}`;
      return `${firstLetter}`;
    } else {
      const firstWordInitial = words[0].charAt(0).toUpperCase();
      const secondWordInitial = words[1].charAt(0).toUpperCase();
      return `${firstWordInitial}${secondWordInitial}`;
    }
  }

  const avatarStyle = {
    backgroundColor: colorCode,
    width: '40px',
    height: '40px',
    fontSize: '14px',
  };

  const selectStyle = {
    cursor: 'crosshair',
    border: 'none', // Remove the border
    outline: 'none', // Remove the focus outline
    width: '100%', // Set the width to fill the cell (excluding the avatar)
    marginLeft: 8, // Add margin to the left of the select box
    background: 'transparent', // Make the background transparent
    fontSize: 'inherit', // Inherit font size from the parent
    color: 'inherit', // Inherit color from the parent
    textAlign: 'left', // Align text to the left
    padding: 0, // Remove padding
  };

  function generateColorCode(name) {
    // Simple hash calculation based on the name
    let hash = 0;
    for (let i = 0; i < name.length; i++) {
      hash += name.charCodeAt(i);
    }

    // Generate a color code using the hash value
    const hue = hash % 360; // Use the hash value modulo 360 to determine the hue
    const saturation = 70; // Set a fixed saturation value
    const lightness = 60; // Set a fixed lightness value

    // Convert HSL values to a CSS color code
    const colorCode = `hsl(${hue}, ${saturation}%, ${lightness}%)`;
    return colorCode;
  }

  const handleLogout = () => {
    // checkAuthentication();

    logoutUser();
    // checkAuthentication();
    navigate('/'); // Use the appropriate route here
  };

  useEffect(() => {
    if (!localStorage.getItem('id_token')) {
      navigate('/');
    }
  }, []);

  const checkAuthentication = () => {
    const cognitoUser = userPool.getCurrentUser();

    if (cognitoUser) {
      cognitoUser.getSession((err, session) => {
        if (err) {
          console.error('Error getting session:', err);
          // Error handling, session is not valid
        } else {
          const idToken = session.getIdToken().getJwtToken();
          // Send the `idToken` to Cognito's token introspection endpoint
          // Check the response to determine if the token is valid
        }
      });
    } else {
      // No current user, indicating the user is logged out
    }
  };

  const [showSuccess, setShowSuccess] = useState(false);
  const [showError, setShowError] = useState(false);

  const showErrorMessage = (message) => {
    setShowError(true);
    setTimeout(() => {
      setShowError(false);
    }, 5000);
  };

  React.useEffect(() => {
    showSuccessMessage();
  }, []);

  const showSuccessMessage = (message) => {
    setShowSuccess(true);
    setTimeout(() => {
      setShowSuccess(false);
    }, 5000);
  };

  return (
    <div className={styles.PageRoot}>
      <Header issubmission={false} issettings={true} />
      <div className={styles.backdrop}>
        <div className={styles.userMenuDropdown}>
          <Tabs
            orientation="vertical"
            value={selectedTab}
            onChange={handleTabChange}
            sx={{
              borderRight: 1,
              borderColor: 'divider',
              display: 'flex',
              alignItems: 'flex-start',
              borderRight: 'none',
              width: '100%',
              marginLeft: '20px',

              gap: '0px',
              '& .MuiTab-root': {
                color: 'gray', // Set the color of the unselected tabs
                '&.Mui-selected': {
                  fontWeight: '900', // Set the font weight of the selected tab
                  fontStyle: 'italic',
                  color: '#000000', // Set the color of the selected tab
                  backgroundColor: '#F5F5F5', // Set the background color of the selected tab
                },
              },
              '& .MuiTabIndicator-root': {
                backgroundColor: '#000000', // Change the indicator color
              },
            }}
            TabIndicatorProps={{
              style: { display: 'none' }, // Hide the indicator
            }}
          >
            <div className={styles.menuSectionTitle}>Personal Settings</div>

            <Tab
              label="Genres"
              icon={<PiHeart size="25" />}
              value={0}
              onClick={handlePreferences}
              sx={{
                textTransform: 'none',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-start', // Align items to the left
                textAlign: 'left', // Align text to the left
                padding: 0,
                minHeight: '50px',
                fontFamily: 'Lato',
                fontWeight: 'bold',
                paddingLeft: '10px',
              }}
              iconPosition="start"
            />
            <Tab
              label="Wishlist"
              icon={<PiListHeart size="25" />}
              value={1}
              onClick={handleWishlist}
              sx={{
                textTransform: 'none',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-start', // Align items to the left
                textAlign: 'left', // Align text to the left
                padding: 0,
                minHeight: '50px',
                fontFamily: 'Lato',
                fontWeight: 'bold',
                paddingLeft: '10px',
              }}
              iconPosition="start"
            />
            <Tab
              label="Decline Template"
              icon={<PiEnvelopeLight size="25" />}
              value={2}
              onClick={handleEmailTemplate}
              sx={{
                textTransform: 'none',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-start', // Align items to the left
                textAlign: 'left', // Align text to the left
                padding: 0,
                minHeight: '50px',
                fontFamily: 'Lato',
                fontWeight: 'bold',
                paddingLeft: '10px',
              }}
              iconPosition="start"
            />

            <div className={styles.menuSectionTitle}>Company Settings</div>

            <Tab
              label="Auto Decline Template"
              icon={<PiGear size="25" />}
              value={3}
              onClick={handleCompanyAutoDecline}
              sx={{
                textTransform: 'none',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-start', // Align items to the left
                textAlign: 'left', // Align text to the left
                padding: 0,
                minHeight: '50px',
                fontFamily: 'Lato',
                fontWeight: 'bold',
                paddingLeft: '10px',
              }}
              iconPosition="start"
            />
            <Tab
              label="Upload Method"
              icon={<PiFileArrowUp size="25" />}
              value={4}
              onClick={handleUploads}
              sx={{
                textTransform: 'none',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-start', // Align items to the left
                textAlign: 'left', // Align text to the left
                padding: 0,
                minHeight: '50px',
                fontFamily: 'Lato',
                fontWeight: 'bold',
                paddingLeft: '10px',
              }}
              iconPosition="start"
            />
            <Tab
              label="Users Management"
              icon={<PiUsersLight size="25" />}
              value={5}
              onClick={handleUserRightsandRoles}
              sx={{
                textTransform: 'none',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-start', // Align items to the left
                textAlign: 'left', // Align text to the left
                padding: 0,
                minHeight: '50px',
                fontFamily: 'Lato',
                fontWeight: 'bold',
                paddingLeft: '10px',
              }}
              iconPosition="start"
            />
          </Tabs>
          {/*  */}
          <div className={styles.profileSection}>
            <div className={styles.profileTitle}>Profile</div>
            <div className={styles.profilerow}>
              <Avatar style={avatarStyle}>{firstLetter}</Avatar>
              <div className={styles.userText}>
                <div className={styles.userName}>{name}</div>
                <div className={styles.userRole}>{role}</div>
                {/* add company name company_name */}
                <div className={styles.userRole}>
                  {companyInfo?.company_name}
                </div>
              </div>
            </div>

            <button className={styles.logoutButton} onClick={handleLogout}>
              <img src={logout} className={styles.logoutIcon} />
              Log Out
            </button>
          </div>
        </div>

        <div className={styles.secondpart}>
          {wishlist && <Wishlist showErrorMessage={showErrorMessage} />}
          {preferences && <Preferences showErrorMessage={showErrorMessage} />}
          {emailTemplate && (
            <EmailTemplate showErrorMessage={showErrorMessage} />
          )}
          {companyAutoDecline && (
            <CompanyAutoDecline showErrorMessage={showErrorMessage} />
          )}
          {manuscriptsUploads && (
            <ManuscriptsUploads
              setManuscriptsUploads={setManuscriptsUploads}
              showErrorMessage={showErrorMessage}
            />
          )}
          {UserRightsandRoles && (
            <ManegUsers showErrorMessage={showErrorMessage} />
          )}
          {/* {Profile && <div>Profile</div>} */}
        </div>
      </div>
    </div>
  );
};

export default UserMenuDropdown;

import React, { useState, useEffect } from 'react';
import {
  AuthenticationDetails,
  CognitoUserPool,
  CognitoUser,
  CognitoUserAttribute,
} from 'amazon-cognito-identity-js';
import UncoverLeft from './UncoverLeft';
import styles from './SignUp.module.css';
import { Link } from 'react-router-dom';
import Line from '../..//icons/AUTH/signin/Line 2.svg';
import men from '../..//icons/AUTH/signin/men.svg';
import MuiPhoneNumber from 'mui-phone-number';
import tooltipIcon from '../../icons/icons8-info (1).svg';
import { useLocation, useNavigate } from 'react-router-dom';
import useApiUtils from '../../useApiUtils';
import { useRecoilState } from 'recoil';
import { userInfoState, idTokeneState } from '../../state'; // Update the path
import step2 from '../..//icons/AUTH/signin/step2.svg';
import CircularProgress from '@mui/material/CircularProgress';

import step3 from '../..//icons/AUTH/signin/step3.svg';
import { renderCenteredAlert } from '../CenteredAlert';

import { v4 as uuidv4 } from 'uuid';

export const generateCorrelationId = () => uuidv4();

const REACT_APP_BUILD_ENV =
  process.env.REACT_APP_BUILD_ENV || 'REACT_APP_BUILD_ENV-build_env';
const REACT_APP_API_ID =
  process.env.REACT_APP_API_ID || 'REACT_APP_BUILD_ENV2-build_env';

const poolData = {
  UserPoolId: process.env.REACT_APP_USER_POOL_ID || 'USER_POOL_ID-build_env',
  ClientId:
    process.env.REACT_APP_CLIENT_ID_USER_POOL ||
    'CLIENT_ID_USER_POOL-build_env',
};

const userPool = new CognitoUserPool(poolData);

const SignUp = (props) => {
  const REACT_APP_BUILD_ENV =
    process.env.REACT_APP_BUILD_ENV || 'REACT_APP_BUILD_ENV-build_env';
  const REACT_APP_API_ID =
    process.env.REACT_APP_API_ID || 'REACT_APP_BUILD_ENV2-build_env';

  const [signupToken, setSignupToken] = useState('');
  const [fullName, setFullName] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [phone, setPhone] = useState('');
  const [confirmationCode, setConfirmationCode] = useState('');
  const [accountCreated, setAccountCreated] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [idToken, setIdToken] = useRecoilState(idTokeneState);
  const [companyId, setCompanyId] = useState('');
  const [saveButtonState, setSaveButtonState] = useState('normal'); // 'normal', 'saving', 'success', 'failure'

  const [fullNameError, setFullNameError] = useState('');
  const [companyNameError, setCompanyNameError] = useState('');
  const [emailError, setEmailError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [phoneError, setPhoneError] = useState('');
  const [confirmationCodeError, setConfirmationCodeError] = useState('');
  const [role, setRole] = useState('manager');
  const [confirmationInProgress, setConfirmationInProgress] = useState(false);
  const [invite_code, setInviteCode] = useState('');

  const {
    getManuscriptsInfo,
    initialgetBookChapter,
    get_book_scores,
    getbookgenrekeywords,
    get_user_info,
    handleNewUserValidation,
    // handleRegisterStorywise,
    // Add other functions from the hook if needed
  } = useApiUtils();

  const [isValidPassword, setIsValidPassword] = useState(true); // Initialize as true since there is no invalid password initially
  const navigate = useNavigate();
  const location = useLocation();

  const handlePasswordChange = (event) => {
    const value = event.target.value;
    // Check the password validity and set the isValidPassword state accordingly
    const isValid =
      value.length >= 8 &&
      /\d/.test(value) &&
      /[a-z]/.test(value) &&
      /[A-Z]/.test(value) &&
      /[^A-Za-z0-9]/.test(value);
    setIsValidPassword(isValid);
    setPassword(value); // Update the password state as well
  };

  const handleOnPhoneChange = (value) => {
    setPhone(value);
  };

  const isCompanyNameValid = (name) => {
    // Allow alphanumeric characters, hyphens, and spaces (if needed)
    const isValid = /^[a-zA-Z0-9\s-]+$/.test(name);
    return isValid;
  };

  const handleConfirmation = (event) => {
    event.preventDefault();

    if (saveButtonState === 'loading') {
      return;
    }
    setSaveButtonState('loading');

    const user = new CognitoUser({
      Username: email,
      Pool: userPool,
    });

    user.confirmRegistration(confirmationCode, true, (error, result) => {
      if (error) {
        setSaveButtonState('normal');
        // console.error('Confirmation error', error);
        // Handle confirmation error (e.g., display error message)
        setConfirmationCodeError(error.message);
      } else {
        if (confirmationInProgress) {
          return;
        }

        setConfirmationInProgress(true);

        // Create authentication details
        const authenticationDetails = new AuthenticationDetails({
          Username: email,
          Password: password,
        });
        // Authenticate the user
        user.authenticateUser(authenticationDetails, {
          onSuccess: (session) => {
            //first clear the local storage
            localStorage.clear();
            const userId = session.getIdToken().payload.sub;
            localStorage.setItem('userId', userId);
            // const idToken = session.getIdToken().getJwtToken();
            setIdToken(session.getIdToken().getJwtToken());
            //store the token in local storage and the refresh token and the access token in the session storage
            localStorage.setItem(
              'access_token',
              session.getAccessToken().getJwtToken()
            );
            localStorage.setItem(
              'access_token_expiration',
              session.getAccessToken().getExpiration()
            );
            localStorage.setItem(
              'id_token',
              session.getIdToken().getJwtToken()
            );
            localStorage.setItem(
              'id_token_expiration',
              session.getIdToken().getExpiration()
            );
            localStorage.setItem('userId', session.getIdToken().payload.sub);
            localStorage.setItem(
              'refresh_token',
              session.getRefreshToken().getToken()
            );
            //save email
            localStorage.setItem('email', email);

            // Now you can proceed with handleRegisterStorywise
            const dataToSend = {
              cognito_id: userId,
              name: fullName,
              company: companyName,
              email: email,
              role: role,
              is_admin_user: userAdmin,
              company_id: companyId,
              phone: phone,
              // only if userAdmin is true then we need to send the signup token
              ...(userAdmin ? { signup_token: signupToken } : {}),
              ...(!userAdmin ? { invite_code: invite_code } : {}),
            };

            handleRegisterStorywise(dataToSend, userId);
            // props.setAuthenticated(true);
          },
          onFailure: (authError) => {
            setSaveButtonState('normal');
            console.error('Authentication error', authError);
            // Handle authentication error
          },
        });
      }
    });
  };

  const handleSignUp = async (event) => {
    event.preventDefault();
    localStorage.clear();

    if (saveButtonState === 'loading') {
      return;
    }

    // Reset any previous error messages
    setFullNameError('');
    setCompanyNameError('');
    setEmailError('');
    setPasswordError('');
    setPhoneError('');
    setErrorMessage('');
    setConfirmationCodeError('');

    // Validate each field and set error messages if needed
    if (!fullName) {
      setFullNameError('Full Name is required');
      return;
    }

    if (!companyName) {
      setCompanyNameError('Company Name is required');
      return;
    }

    if (!isCompanyNameValid(companyName)) {
      setCompanyNameError(
        'Company Name can only contain alphanumeric characters, hyphens, and spaces'
      );
      return;
    }

    if (!email) {
      setEmailError('Email is required');
      return;
    }

    if (!isValidPassword) {
      setPasswordError(
        'Password must be at least 8 characters long and contain at least one uppercase letter, one lowercase letter, one number, and one special character.'
      );
      return;
    }

    if (!phone) {
      setPhoneError('Phone is required');
      return;
    }
    if (userAdmin) {
      if (!signupToken) {
        setConfirmationCodeError('Signup token is required');
        return;
      }
    }
    setSaveButtonState('loading');

    try {
      // here is going to be call to cognito user validation
      const dataToSend = {
        company: companyName,
        email: email,
        is_admin_user: userAdmin,
        ...(userAdmin ? { signup_token: signupToken } : {}),
        ...(!userAdmin ? { company_id: companyId } : {}),
        ...(!userAdmin ? { invite_code: invite_code } : {}),
      };

      const response = await handleNewUserValidation(dataToSend);

      if (!response) {
        // setErrorMessage(response.error_msg);
        console.log(response.error_msg);
        setSaveButtonState('normal');
        return;
      }
      console.log('user is validated');
    } catch (error) {
      console.log(error);
      setSaveButtonState('normal');
      return;
    }

    //remove spaces from phone number
    const phone_number = phone.replace(/\s/g, '');
    const attributes = [
      new CognitoUserAttribute({ Name: 'custom:full_name', Value: fullName }),
      new CognitoUserAttribute({
        Name: 'custom:company_name',
        Value: companyName,
      }),
      new CognitoUserAttribute({ Name: 'email', Value: email }),
      new CognitoUserAttribute({ Name: 'phone_number', Value: phone_number }),
    ];

    userPool.signUp(email, password, attributes, null, (error, result) => {
      if (error) {
        // console.error('Sign-up error', error);
        // extract the error message without the first pat of "Error: "
        // const errorMessage = error.message.split(':')[1];
        // setErrorMessage('Sign-up error'+`  `+ errorMessage);
        setErrorMessage(error.message);
        setSaveButtonState('normal');
        setFullNameError('');
        setCompanyNameError('');
        setEmailError('');
        setPasswordError('');
        setPhoneError('');
        setConfirmationCodeError('');
        // Handle sign-up error (e.g., display error message)
      } else {
        setSaveButtonState('normal');
        setAccountCreated(true);
        // Handle successful sign-up (e.g., redirect to login page)
      }
    });
  };

  const [userAdmin, setUserAdmin] = useState(true);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);

    setFullName(queryParams.get('fullName') || '');
    setCompanyName(queryParams.get('companyName') || 'test');
    setEmail(queryParams.get('email') || '');
    setPhone(queryParams.get('phone') || '');
    // setRole(queryParams.get('role') || '');
    setCompanyId(queryParams.get('company') || '');
    if (queryParams.get('email')) {
      setUserAdmin(false);
      const roleQueryParam = queryParams.get('role');
      let role = '';
      if (roleQueryParam?.includes('Editor')) {
        role = 'editor';
      } else if (roleQueryParam?.includes('Manager')) {
        role = 'manager';
      }

      setRole(role);
      setInviteCode(queryParams.get('invite_code'));
    }
  }, [location.search]);

  const handleRegisterStorywise = async (dataToSend, registration) => {
    try {
      const correlationId = generateCorrelationId(); // Generate Correlation ID once and store it

      const baseURL = process.env.REACT_APP_BASE_URL || 'http://127.0.0.1:8000';

      const response = await fetch(`${baseURL}/api/user/register_user`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `${localStorage.getItem('id_token')}`,
          'Correlation-ID': correlationId, // Attach Correlation ID here
        },
        body: JSON.stringify(dataToSend),
      });

      if (response.ok) {
        setSaveButtonState('normal');

        get_user_info(false);
        if (dataToSend.is_admin_user) {
          navigate(
            `/set-preferences?email=${encodeURIComponent(
              dataToSend.email
            )}&superadmin=true`
          );
        } else {
          navigate(
            `/set-preferences?email=${encodeURIComponent(
              dataToSend.email
            )}&superadmin=false`
          );
        }
      } else {
        setSaveButtonState('normal');

        // Handle API request error
        // get_user_info(false);
        console.error('API request error:', response.statusText);
        try {
          const errorResponse = await response.json();
          if (errorResponse.error_msg) {
            renderCenteredAlert(errorResponse.error_msg);
          } else {
            renderCenteredAlert(response.statusText);
          }
        } catch (error) {
          renderCenteredAlert(response.statusText);
        }
        // Handle any error message or state update you need
      }
    } catch (error) {
      // Handle fetch error
      setSaveButtonState('normal');

      console.error('Fetch error:', error);
      // Handle any error message or state update you need
    }
  };

  const handleResendCode = () => {
    const user = new CognitoUser({
      Username: email,
      Pool: userPool,
    });

    user.resendConfirmationCode((error, result) => {
      if (error) {
        // Handle the error, e.g., display an error message
        console.error('Error resending confirmation code:', error);
      } else {
        // Confirmation code resent successfully
        // You can provide feedback to the user here if needed
      }
    });
  };

  return (
    <div className={styles.container}>
      <UncoverLeft />
      <div className={styles.logosignincontainer}>
        {!accountCreated && (
          <div className={styles.signcontainer}>
            <div className={styles.hedder}>
              {userAdmin && (
                <div className={styles.steps}>
                  <div className={styles.step1}>
                    <div className={styles.step1circle}>
                      <img src={men} className={styles.men} />
                    </div>
                    <div className={styles.step1text}>
                      step 1/3
                      <div className={styles.step1text1}>Create Account</div>
                    </div>
                  </div>
                  <div className={styles.line1}>
                    <div className={styles.line1img}>
                      <img src={Line} alt="line" />
                    </div>
                  </div>
                  <div className={styles.step2}>
                    <div className={styles.step2circle}>
                      <img src={step2} className={styles.menstep2} />
                    </div>
                  </div>
                  <div className={styles.line1}>
                    <div className={styles.line1img}>
                      <img src={Line} alt="line" />
                    </div>
                  </div>
                  <div className={styles.step3}>
                    <div className={styles.step3circle}>
                      <img src={step3} className={styles.menstep3} />
                    </div>
                  </div>
                </div>
              )}
              <div className={styles.signup}>
                <div className={styles.backtologin}>
                  Already have an account?
                </div>
                <Link to="/" className={styles.linkto}>
                  Log in
                </Link>
              </div>
            </div>
            <div className={styles.body}>
              <div className={styles.titlelogo}>
                <div className={styles.loginparttitle}>
                  Welcome to Storywise,
                </div>
                <div className={styles.loginparttitlepart2}>
                  let’s get you set up!
                </div>
              </div>

              <div className={styles.placetoerror}>
                {errorMessage && (
                  <p className={styles['error-messagegeneral']}>
                    {errorMessage}
                  </p>
                )}
              </div>
              <div onSubmit={handleSignUp} className={styles.formpart}>
                <div className={styles.label_tooltip}>
                  <label className={styles.label}>Full Name </label>
                  {fullNameError && (
                    <p className={styles['error-message']}>{fullNameError}</p>
                  )}
                </div>
                <input
                  type="text"
                  value={fullName}
                  onChange={(e) => setFullName(e.target.value)}
                  required
                  className={styles.input}
                  placeholder="Enter Full Name"
                />
                <div className={styles.label_error}>
                  <label className={styles.label}>Company Name </label>
                  {companyNameError && (
                    <p className={styles['error-message']}>
                      {companyNameError}
                    </p>
                  )}
                </div>
                {userAdmin && (
                  <input
                    type="text"
                    value={companyName}
                    onChange={(e) => setCompanyName(e.target.value)}
                    required
                    className={styles.input}
                    placeholder="Enter Company Name"
                  />
                )}
                {!userAdmin && (
                  <input
                    type="text"
                    value={companyName}
                    onChange={(e) => setCompanyName(e.target.value)}
                    readOnly
                    className={styles.input}
                    placeholder="Enter Company Name"
                  />
                )}
                <div className={styles.label_error}>
                  <label className={styles.label}>Email </label>
                  {emailError && (
                    <p className={styles['error-message']}>{emailError}</p>
                  )}
                </div>
                {userAdmin && (
                  <input
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                    className={styles.input}
                    placeholder="Enter Email"
                  />
                )}
                {!userAdmin && (
                  <input
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                    className={styles.input}
                    placeholder="Enter Email"
                  />
                )}
                <div className={styles.label_tooltip}>
                  <label className={styles.label}>Password</label>
                  <div className={styles.description}>
                    <div className={styles.tooltip}>
                      <span className={styles.tooltipText}>
                        Password must be at least 8 characters long and contain
                        at least one uppercase letter, one lowercase letter, one
                        number and one special character. and includes no
                        obvious personal information or common words.
                      </span>
                    </div>
                    <img
                      src={tooltipIcon}
                      alt="tooltip"
                      className={styles.tooltipIcon}
                    />
                  </div>

                  {passwordError && (
                    <p
                      className={`${styles['error-message']}  ${styles.passwordError}`}
                    >
                      {passwordError}
                    </p>
                  )}
                </div>
                <input
                  type="password"
                  value={password}
                  onChange={handlePasswordChange}
                  required
                  className={styles.input}
                  // className={`${styles.input} ${
                  //   isValidPassword ? styles.valid : styles.invalid
                  // }`}
                  placeholder="Enter Password"
                />
                <div className={styles.label_error}>
                  <label className={styles.label}>Phone {'    '}</label>
                  {phoneError && (
                    <p className={styles['error-message']}>{phoneError}</p>
                  )}
                </div>

                <MuiPhoneNumber
                  defaultCountry={'us'}
                  autoFormat={false}
                  disableAreaCodes={true}
                  onChange={handleOnPhoneChange}
                  InputProps={{
                    disableUnderline: true,
                  }}
                  style={{
                    padding: '0.8vh',
                    alignSelf: 'stretch',
                    borderRadius: '16px',
                    fontWeight: 500,
                    fontSize: '2vh',
                    border: '1px solid var(--storywise-gainsboro, #DDDEE0)',
                  }}
                  sx={{ '& svg': { height: '1em' } }}
                />

                {userAdmin && (
                  <>
                    <div className={styles.label_tooltip}>
                      <label className={styles.label}> Signup token </label>
                      {confirmationCodeError && (
                        <p className={styles['error-message']}>
                          {confirmationCodeError}
                        </p>
                      )}
                    </div>
                    <input
                      type="text"
                      value={signupToken}
                      onChange={(e) => setSignupToken(e.target.value)}
                      required
                      className={styles.input}
                      placeholder="Please enter your signup token provided by Storywise sales team"
                      autoComplete="off"
                    />
                  </>
                )}
              </div>
            </div>
            <div className={styles.fotter}>
              <div className={styles.pagenumber}>
                <div className={styles.pagenumberfirst}>1</div>/
                {userAdmin ? '6' : '4'}
              </div>
              {saveButtonState === 'loading' && (
                <div className={styles.button} onClick={handleSignUp}>
                  <CircularProgress
                    size={24}
                    className={styles.buttonProgress}
                  />
                </div>
              )}
              {saveButtonState !== 'loading' && (
                <div className={styles.button} onClick={handleSignUp}>
                  <div className={styles.textbutton}>Create Account</div>
                </div>
              )}
            </div>
          </div>
        )}
        {accountCreated && (
          <div className={styles.signcontainer}>
            <div className={styles.hedder}>
              {userAdmin && (
                <div className={styles.steps}>
                  <div className={styles.step1}>
                    <div className={styles.step1circle}>
                      <img src={men} className={styles.men} />
                    </div>
                    <div className={styles.step1text}>
                      step 1/3
                      <div className={styles.step1text1}>Create Account</div>
                    </div>
                  </div>
                  <div className={styles.line1}>
                    <div className={styles.line1img}>
                      <img src={Line} alt="line" />
                    </div>
                  </div>
                  <div className={styles.step2}>
                    <div className={styles.step2circle}>
                      <img src={step2} className={styles.menstep2} />
                    </div>
                  </div>
                  <div className={styles.line1}>
                    <div className={styles.line1img}>
                      <img src={Line} alt="line" />
                    </div>
                  </div>
                  <div className={styles.step3}>
                    <div className={styles.step3circle}>
                      <img src={step3} className={styles.menstep3} />
                    </div>
                  </div>
                </div>
              )}
              <div className={styles.signup}>
                <div className={styles.backtologin}>
                  Already have an account?
                </div>
                <Link to="/" className={styles.linkto}>
                  Log in
                </Link>
              </div>
            </div>
            <div className={styles.loginpart}>
              <div className={styles.loginparttitle}>Confirm your account </div>
              <div className={styles.loginpartsubtitle}>
                Please enter the confirmation code sent to your email address.
              </div>
              <div className={styles.formpart}>
                <input
                  type="text"
                  value={confirmationCode}
                  onChange={(e) => setConfirmationCode(e.target.value)}
                  required
                  className={styles.input}
                />
                {/* <div type="button" onClick={handleConfirmation} className={styles.button}>Confirm</div> */}
              </div>
              {confirmationCodeError && (
                <p className={styles['error-message']}>
                  {confirmationCodeError}
                </p>
              )}

              <div className={styles.lastrow}>
                {saveButtonState === 'loading' && (
                  <div
                    className={styles.loginbutton}
                    onClick={handleConfirmation}
                  >
                    <CircularProgress
                      size={24}
                      className={styles.buttonProgress}
                    />
                  </div>
                )}
                {saveButtonState !== 'loading' && (
                  <div
                    className={styles.loginbutton}
                    onClick={handleConfirmation}
                  >
                    <div className={styles.loginbuttontext}>Confirm</div>
                  </div>
                )}
              </div>
              <div onClick={handleResendCode} className={styles.linkto}>
                Resend Confirmation Code
              </div>
            </div>
            <div className={styles.fotter}>
              <div className={styles.pagenumber}>
                <div className={styles.pagenumberfirst}>1</div>/
                {userAdmin ? '6' : '4'}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default SignUp;

import { atom, selector } from 'recoil';

///////////Compare Page //////////
export const genresState = atom({
  key: 'genres',
  default: [],
});

export const keywordsState = atom({
  key: 'keywords',
  default: [],
});

export const genresDataState = atom({
  key: 'similar_books',
  default: {},
});

export const readerViewState = atom({
  key: 'readerViewState',
  default: [],
});

export const isNewVersionSimilarBooksState = atom({
  key: 'isNewVersionSimilarBooksState',
  default: true,
});

///add indicator for loading
export const loadingStateCompare = atom({
  key: 'loadingStateCompare',
  default: true,
});

/////////// end Compare Page //////////

/////////// Reviwe Page ///////////////

export const chapterNamesAtom = atom({
  key: 'chapterNamesAtom',
  default: [],
});

export const contentAtom = atom({
  key: 'contentAtom',
  default: [],
});

export const notesAtom = atom({
  key: 'notesAtom',
  default: [],
});

export const highlightedTextAtom = atom({
  key: 'highlightedTextAtom',
  default: [],
});

// Define the atom to store the response data
export const scoreDataAtom = atom({
  key: 'scoreDataAtom',
  default: {},
});

// embeddings data
export const embeddingsDataAtom = atom({
  key: 'embeddingsDataAtom',
  default: {},
});

// chat history
export const chatHistoryAtom = atom({
  key: 'chatHistoryAtom',
  default: [],
});

// chat history devide to messages
export const chatHistoryMessagesAtom = atom({
  key: 'chatHistoryMessagesAtom',
  default: [],
});

//define selected chapter index
export const selectedChapterAtom = atom({
  key: 'selectedChapterAtom',
  default: 0,
});
// false= no change or delete, or edit true= add
export const changeInNotesAtom = atom({
  key: 'changeInNotesAtom',
  default: false,
});
export const afterGetBookChapter = atom({
  key: 'afterGetBookChapter',
  default: true,
});

/////////// end Reviwe Page ////////////

/////////// Submission Page //////////
export const manuscriptInfoListAtom = atom({
  key: 'manuscriptInfoList',
  default: [],
});

export const clickedManuscriptAtom = atom({
  key: 'clickedManuscript',
  default: [],
});

export const most_similar_bookAtom = atom({
  key: 'most_similar_book',
  default: [],
});

/////////// end Submission Page //////////

export const currentManuscriptAtom = atom({
  key: 'currentManuscript',
  default: [],
});

/////////////upload file/////////////////////
//store number of uploaded
export const numberOfUploadedFilesState = atom({
  key: 'numberOfUploadedFilesState',
  default: 0,
});

export const fileState = atom({
  key: 'fileState',
  default: [],
});

// fileInfoState is array of element each element store info about file
export const fileInfoState = atom({
  key: 'fileInfoState',
  default: [],
});

//create var to store the number of files that was uploaded recently- in the last upload
export const numberOfUploadedFilesRecentlyState = atom({
  key: 'numberOfUploadedFilesRecentlyState',
  default: 0,
});

// clickedUploadFileState is contain index of file that user clicked on it
export const clickedUploadFileState = atom({
  key: 'clickedUploadFileState',
  default: [],
});

// indicator for loading get manuscript info
export const loadingmanuscriptState = atom({
  key: 'loadingmanuscriptState',
  default: false,
});

// indicator for initial highlight
export const initialHighlightState = atom({
  key: 'initialHighlightState',
  default: true,
});

// variable to store the number of pending_processing
export const pendingProcessingState = atom({
  key: 'pendingProcess',
  default: 0,
});

// variable to store the header_columns_translation
export const headerColumnsTranslationState = atom({
  key: 'headerColumnsTranslationState',
  default: [],
});

//variable to store the columns
export const columnsState = atom({
  key: 'columnsState',
  default: [],
});

//variable to store the the numner of manuscripts rows that should be, the number of rows in the table + the
//number of the manuscripts that was uploaded
// export const numberOfManuscriptsState = atom({
//   key: 'numberOfManuscriptsState',
//   default: 0,
// });
//indicator that i startPolling
export const startPollingState = atom({
  key: 'startPollingState',
  default: false,
});
//user profile data

export const wishlistMenuState = atom({
  key: 'wishlistMenuState',
  default: false,
});

export const preferencesState = atom({
  key: 'preferencesState',
  default: false,
});
export const emailTemplateState = atom({
  key: 'emailTemplateState',
  default: false,
});
export const CompanyAutoDeclineState = atom({
  key: 'CompanyAutoDeclineState',
  default: false,
});

export const manuscriptsUploadsState = atom({
  key: 'manuscriptsUploadsState',
  default: false,
});
export const UserRightsandRolesState = atom({
  key: 'UserRightsandRolesState',
  default: false,
});
//upload method
export const numberOfUploadsState = atom({
  key: 'numberOfUploadsState',
  default: 0,
});

export const uploadMethodOptionState = atom({
  key: 'uploadMethodOptionState',
  default: [
    { value: 'Manual uploads', selected: true },
    { value: 'Integrate your existing email account', selected: false },
    {
      value: 'Forward emailed submissions straight to your dashboard',
      selected: false,
    },
  ],
});

export const uploadMethodcheckedState = atom({
  key: 'uploadMethodcheckedState',
  default: false,
});

export const uploadMethodProviderState = atom({
  key: 'uploadMethodProviderState',
  default: null,
});

/////usser info
export const userInfoState = atom({
  key: 'userInfoState',
  default: null, // Initially, user_info will be null or an empty object
});

export const idTokeneState = atom({
  key: 'idTokeneState',
  default: null, // Initially, user_info will be null or an empty object
});

export const accessTokenState = atom({
  key: 'accessTokenState',
  default: null, // Initially, user_info will be null or an empty object
});

export const books_id_dwonloadState = atom({
  key: 'books_id_dwonloadState',
  default: [], // Initially, user_info will be null or an empty object
});

//Company Information
export const companyInfoState = atom({
  key: 'companyInfoState',
  default: null, // Initially, user_info will be null or an empty object
});

/// email address to send approval
export const emailToApproveState = atom({
  key: 'emailToApproveState',
  default: null, // Initially, user_info will be null or an empty object
});

export const notes_highlightsState = atom({
  key: 'notes_highlightsState',
  default: [],
});

export const userRejected_ApprovedNoteState = atom({
  key: 'userRejected_ApprovedNoteState',
  default: [],
});

//author info for submission page
export const authorInfoState = atom({
  key: 'authorInfoState',
  default: null, // Initially, user_info will be null or an empty object
});

//synopsis info for about page
export const synopsisState = atom({
  key: 'synopsisState',
  default: null, // Initially will be null or an empty object
});

//show chat
export const showChatState = atom({
  key: 'showChatState',
  default: false, // Initially, user_info will be null or an empty object
});

export const goToApproveState = atom({
  key: 'goToApproveState',
  default: false, // Initially, user_info will be null or an empty object
});

export const goToRejectState = atom({
  key: 'goToRejectState',
  default: false, // Initially, user_info will be null or an empty object
});

export const selectManuscriptByRatingState = atom({
  key: 'selectManuscriptByRatingState',
  default: false, // Initially, user_info will be null or an empty object
});

//data for ingest_details
export const ingestDetailsState = atom({
  key: 'ingestDetailsState',
  default: null,
});

// Copilot
export const copilotStateAtom = atom({
  key: 'copilotState',
  default: {
    threads: [],
  },
});

export const usersandCompaniesStateAtom = atom({
  key: 'usersandCompaniesState',
  default: null,
});

// atom state for manual_login
export const manualLoginState = atom({
  key: 'manualLoginState',
  default: false,
});

//author info for submission page
export const bookTasteInfoState = atom({
  key: 'bookTasteInfoState',
  default: null,
});

export const wishlistState = atom({
  key: 'wishlistState',
  default: null,
});

import styles from './ReaderViewNotes.module.css';
import { FaEdit, FaTrash, FaPlus, FaClock } from 'react-icons/fa';
import CircularProgress from '@mui/material/CircularProgress';

import { useRecoilState } from 'recoil';
import {
    userInfoState
} from '../../../state';


import React, { useState, useEffect, useRef } from 'react';


const ReaderViewNotes = ({
    readerView,
    manageReaderViewNote,
    clickedManuscript,
    setLoading,
}) => {
    const [newReaderViewContent, setNewReaderViewContent] = useState('');
    const [editingNote, setEditingNote] = useState(null);
    const [updatedContent, setUpdatedContent] = useState('');
    const [showAddNote, setShowAddNote] = useState(true);
    const [addButtonState, setAddButtonState] = useState('default');
    const [saveButtonState, setSaveButtonState] = useState('default');
    const [deleteButtonState, setDeleteButtonState] = useState('default');
    const [deletedNoteID, setDeletedNoteID] = useState(null);
    const [sortedReaderView, setSortedReaderView] = useState([]);

    const [userInfo] = useRecoilState(userInfoState);
    // const toggleAddNote = () => setShowAddNote(!showAddNote);
    const textAreaRef = useRef(null);

    const getButtonText = (state, defaultText) => {
        switch (state) {
            case 'loading':
                return <CircularProgress size={15} color="inherit" />;
            case 'success':
                return 'Success';
            case 'failure':
                return 'Failed';
            default:
                return defaultText;
        }
    };

    const addReaderViewNote = async () => {
        if (!newReaderViewContent.trim()) {
            alert('Note content cannot be empty.');
            return;
        }

        setAddButtonState('loading');
        const payload = {
            action: 'add',
            book_id: clickedManuscript.book_id,
            content: newReaderViewContent,
        };

        try {
            setLoading(true);
            await manageReaderViewNote(payload);
            setNewReaderViewContent('');
            setAddButtonState('success');
        } catch (error) {
            console.error('Error adding reader view note:', error);
            setAddButtonState('failure');
        } finally {
            setLoading(false);
            setTimeout(() => setAddButtonState('default'), 2000);
        }
    };

    const editReaderViewNote = (readerViewNoteId, content) => {
        setEditingNote(readerViewNoteId);
        setUpdatedContent(content);
    };

    const saveUpdatedNote = async () => {
        if (!updatedContent.trim()) {
            alert('Note content cannot be empty.');
            return;
        }

        setSaveButtonState('loading');
        const payload = {
            action: 'update',
            note_id: editingNote,
            content: updatedContent,
            book_id: clickedManuscript.book_id,
        };

        try {
            setLoading(true);
            await manageReaderViewNote(payload);
            setEditingNote(null);
            setUpdatedContent('');
            setSaveButtonState('success');
        } catch (error) {
            console.error('Error updating reader view note:', error);
            setSaveButtonState('failure');
        } finally {
            setLoading(false);
            setTimeout(() => setSaveButtonState('default'), 2000);
        }
    };

    const deleteReaderViewNote = async (readerViewNoteId) => {
        if (!window.confirm('Are you sure you want to delete this note?')) return;

        setDeleteButtonState('loading');
        setDeletedNoteID(readerViewNoteId);
        const payload = {
            action: 'delete',
            note_id: readerViewNoteId,
            book_id: clickedManuscript.book_id,
        };

        try {
            setLoading(true);
            await manageReaderViewNote(payload);
            setDeleteButtonState('success');
            setDeletedNoteID(null);
        } catch (error) {
            console.error('Error deleting reader view note:', error);
            setDeleteButtonState('failure');
            setDeletedNoteID(null);
        } finally {
            setLoading(false);
            setTimeout(() => setDeleteButtonState('default'), 2000);
        }
    };

    useEffect(() => {

        const tempsortedReaderView = [...(readerView || [])].sort((a, b) => {
            const timeA = a.updated_ts.split("T")[1]; // Extract time part "HH:MM:SS.mmm"
            const timeB = b.updated_ts.split("T")[1];

            const [hourA, minA, secA] = timeA.split(":");
            const [hourB, minB, secB] = timeB.split(":");

            // Prioritize minutes:seconds in descending order
            if (minA !== minB) {
                return parseInt(minB) - parseInt(minA);
            }
            if (secA !== secB) {
                return parseFloat(secB) - parseFloat(secA);
            }

            // Fallback to full date for tie-breaking (descending order)
            const dateA = new Date(a.updated_ts).getTime();
            const dateB = new Date(b.updated_ts).getTime();
            return dateB - dateA;
        });

        // Log sorted results for verification
        console.log("Sorted by minutes and seconds (descending):", tempsortedReaderView);

        // Update the state
        setSortedReaderView(tempsortedReaderView);
    }
        , [readerView]);


    // Handle "Enter" key for saving
    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            addReaderViewNote();
        }
    };

    // Handle click outside to clear textarea
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (textAreaRef.current && !textAreaRef.current.contains(event.target)) {
                setNewReaderViewContent('');
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);
    const handleResize = (textarea) => {
        textarea.style.height = 'auto'; // Reset height to calculate proper scroll height
        textarea.style.height = `${textarea.scrollHeight}px`; // Set height to match content
    };


    return (
        <div className={styles.moreInfoPart2}>
            <div className={styles.firstTitle}>
                <div className={styles.moreInfoTitle}>Reader Reviews Notes</div>
            </div>
            <div className={styles.part2}>

                {addButtonState === 'loading' ? (
                    <CircularProgress size={30} style={{ color: 'white' }}  />
                ) : (
                <div className={styles.addNotesSectionRoot}>
                    <textarea
                        type="text"
                        placeholder="Add your review notes here..."
                        value={newReaderViewContent}
                        onChange={(e) => setNewReaderViewContent(e.target.value)}
                        className={styles.addreaderViewNoteItem}
                    />
                        <div
                            className={styles.Post}
                            onClick={addReaderViewNote}
                          >
                            <div className={styles.Postext}>Post</div>
                          </div>
                </div>
                
                )}

            <div className={styles.readerViewNotesList}>
                {sortedReaderView.length != 0 && (
                    sortedReaderView.map((note) =>
                        note.note_id === editingNote ? (
                            <div key={note.note_id} className={styles.readerViewNoteItem}>
                            <textarea
                                className={styles.editreaderViewNoteItem}
                                value={updatedContent}
                                onChange={(e) => {
                                    setUpdatedContent(e.target.value);
                                    handleResize(e.target); // Adjust height dynamically
                                }}
                                placeholder="Edit note content..."
                                ref={(el) => {
                                    if (el) {
                                        handleResize(el); // Adjust height on initial render
                                    }
                                }}
                            />
                            <div className={styles.ButtonContainer}>
                                <button
                                    className={styles.CancelButtonContainer}
                                    onClick={() => setEditingNote(null)}
                                >
                                    Cancel
                                </button>
                                <button
                                    className={styles.SaveButtonContainer}
                                    onClick={saveUpdatedNote}
                                >
                                    {getButtonText(saveButtonState, 'Save')}
                                </button>
                            </div>
                        </div>
                        ) : (
                            <div key={note.note_id} className={styles.readerViewNoteItem}>
                                <div className={styles.NoteHeader}>
                                    <div className={styles.NoteDetails}>
                                        <div className={styles.Avatar}>
                                            {note.user_name?.charAt(0).toUpperCase()}
                                        </div>
                                        <div className={styles.UserName}>{note.user_name}</div>
                                    </div>
                                    <div className={styles.NoteActions}>
                                        <div className={styles.Timestamps}>
                                            <span className={styles.CreatedTimestamp}>
                                                <FaClock className={styles.ClockIcon} />
                                                {new Date(note.updated_ts).toLocaleDateString()}
                                            </span>
                                        </div>
                                        <FaEdit
                                            className={styles.editIcon}
                                            onClick={() =>
                                                editReaderViewNote(note.note_id, note.content)
                                            }
                                        />
                                        {deleteButtonState === 'loading' &&
                                            deletedNoteID === note.note_id ? (
                                            <CircularProgress size={15} sx={{ color: 'white' }} />
                                        ) : (
                                            <FaTrash
                                                className={styles.deleteIcon}
                                                onClick={() =>
                                                    deleteReaderViewNote(note.note_id)
                                                }
                                            />
                                        )}
                                    </div>
                                </div>
                                <div className={styles.NoteSection}>
                                  {note.content}
                                </div>
                                <div className={styles.NoteFooter}>
                                    <div className={styles.Timestamps}>
                                        {note.updated_ts && note.created_ts !== note.updated_ts && (
                                            <span className={styles.EditedStatus}>Edited</span>
                                        )}
                                    </div>
                                </div>
                            </div>
                        )
                    )
                )}
            </div>
        </div>
        </div>
    );
};

export default ReaderViewNotes;

import styles from './CompanyAutoDeclineSA.module.css';


import React, { useState, useEffect } from 'react';
import { useNavigate ,useLocation} from 'react-router-dom';

import { renderCenteredAlert, renderCenteredInfo } from '../CenteredAlert';

import CompanyDecline from '../propileInfo/CompanyDecline';
import UserDecline from '../propileInfo/UserDecline';
import UncoverLeft from './UncoverLeft';
import Line from '../..//icons/AUTH/signin/Line 2.svg';
import Line2 from '../..//icons/AUTH/signin/Line 3.svg';
import back from '../..//icons/back.svg';
import step2 from '../..//icons/AUTH/signin/curentstep2.svg';
import step3 from '../..//icons/AUTH/signin/step3.svg';
import { Link } from 'react-router-dom';

import men from '../..//icons/AUTH/signin/men.svg';

const CompanyAutoDeclineSA = (props) => {


  const [buttonTextNormal, setButtonTextNormal] = useState('Next');
  const [showBackButton, setShowBackButton] = useState(true);
  const [afterSave, setAfterSave] = useState(false);
  const [superadmin, setSuperadmin] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const superadmin = queryParams.get('superadmin');
    // if (superadmin === 'true') {
      setSuperadmin(true);
    // }
  }, []);

  useEffect(() => {
    if (afterSave) {
      handleNextClick();
    }
  }, [afterSave]);

  const handleBackClick = () => {
    navigate(`/user-manual-decline${location.search}`);
  };


  const handleNextClick = async () => {
    if (superadmin) {
      navigate(`/add-user${location.search}`); // Change this to your desired redirect path

    } else {
      navigate(`/submissions`);

      }
  };



  return (
    <div className={styles.container}>
    <UncoverLeft />
    <div className={styles.logosignincontainer}>
      <div className={styles.signcontainer}>
        <div className={styles.hedder}>
          <div className={styles.steps}>
            <div className={styles.step1}>
              <div className={styles.step1circle}>
                <img src={men} className={styles.men} />
              </div>
            </div>
            <div className={styles.line1}>
              <div className={styles.line1img}>
                <img src={Line2} alt="line" />
              </div>
            </div>
            <div className={styles.step1}>
              <div className={styles.step2circle}>
                <img src={step2} className={styles.menstep2} />
              </div>
              <div className={styles.step1text}>
                step 2/3
                <div className={styles.step1text1}>Set Your Preferences</div>
              </div>
            </div>
            <div className={styles.line1}>
              <div className={styles.line1img}>
                <img src={Line} alt="line" />
              </div>
            </div>
            <div className={styles.step3}>
              <div className={styles.step3circle}>
                <img src={step3} className={styles.menstep3} />
              </div>
            </div>
          </div>

          <div className={styles.signup}>
            <div className={styles.backtologin}>Already have an account?</div>
            <Link to="/" className={styles.linkto}>
              Log in
            </Link>
          </div>
        </div>
      <CompanyDecline
                  superadmin={superadmin}
        buttonTextNormal={buttonTextNormal} setButtonTextNormal={setButtonTextNormal}
        showBackButton={showBackButton} pressBackButton={handleBackClick}
        afterSave={afterSave} setAfterSave={setAfterSave} />
   </div>
      </div>
    </div>
  );
  
};

export default CompanyAutoDeclineSA;

import React from 'react';
import styles from './ScoreDisplay.module.css';

const letterColors = {
  A: '#a2c31e',
  B: '#26D0D0',
  C: '#264BD0',
  D: '#D05D0A',
  E: '#EB6767',
};

const numberColors = {
  1: '#00EEB0',
  2: '#39FF35',
  3: '#D735FF',
  4: '#DA35FF',
  5: '#8A0B1A',
};

const ScoreDisplay = (props) => {
  const letterColor = letterColors[props.letterScore] || '#CCCCCC';
  // const numberColor = numberColors[props.numberScore] || '#CCCCCC'
  const numberColor = numberColors[1] || '#CCCCCC';

  // Ensure both letterColor and numberColor are valid
  if (!letterColor || !numberColor) {
    console.error(
      'Invalid letterScore or numberScore provided:',
      props.letterScore,
      props.numberScore
    );
    return null; // or return a default element
  }

  const backgroundStyle = {
    // background: `linear-gradient(102deg, ${letterColor} 10%, ${numberColor} 129%)`,
    backgroundColor: `${letterColor}`,
    borderRadius: '4px',
  };

  return (
    <div className={styles.scoreContainer} style={backgroundStyle}>
      {props.letterScore}
      {` `}
      {/* {props.numberScore} */}
    </div>
  );
};

export default ScoreDisplay;


import Business from './icons/genreImage/genre/Business.svg';
import Educational from './icons/genreImage/genre/Educational.svg';
import fantasy from './icons/genreImage/genre/fantasy.svg';
import GamesGaming from './icons/genreImage/genre/image 15.svg';
import historic from './icons/genreImage/genre/historic.svg';
import History from './icons/genreImage/genre/architype/Rags to Riches.svg';
import HomeLifestyle from './icons/genreImage/genre/Home & Lifestyle.svg';
import horror from './icons/genreImage/genre/horror.svg';
import Memoir1 from './icons/genreImage/genre/genre/genre/genre/fiction.svg';
import NatureClimate from './icons/genreImage/genre/Nature & Climate.svg';
import Paranormalromance from './icons/genreImage/genre/genre/adult.svg';
import Parenting from './icons/genreImage/genre/Parenting.svg';
import PeriodRomance from './icons/genreImage/genre/Period Romance.svg';
import PopularScience from './icons/genreImage/genre/Popular Science.svg';
import Religion from './icons/genreImage/genre/Religion.svg';
import romance from './icons/genreImage/genre/romance.svg';
import Selfhelp from './icons/genreImage/genre/Self help.svg';
import SportsHobbies from './icons/genreImage/genre/Sport & Hobbies.svg';
import Travel from './icons/genreImage/genre/Travel.svg';
import mysteryCrime from './icons/genreImage/Mystery:Crime.svg';
import ContemporaryRomance from './icons/genreImage/genre/genre/romance.svg';
import Academic from './icons/genreImage/genre/Academic.svg';
import generalfiction from './icons/genreImage/genre/genre/architype/Comedy.svg';
import generalnonfiction from './icons/genreImage/genre/genre/architype/Tragedy.svg';
import Collectibles from './icons/genreImage/genre/genre/Collectibles.svg';

import Thriller from './icons/genreImage/MysteryThriller.svg';
import Mystery from './icons/genreImage/MysteryThriller.svg';
import HistoricalFiction from './icons/genreImage/HistoricalFiction.svg';
import Romance from './icons/genreImage/Romance.svg';
import Horror from './icons/genreImage/Horror.svg';
import Fantasy from './icons/genreImage/ScifiFantasy.svg';
import SciFi from './icons/genreImage/genre/genre/architype/Rebirth.svg';
import Memoir from './icons/genreImage/Memoir.svg';
import YA from './icons/genreImage/genre/ya.svg';
import LiteraryFiction from './icons/genreImage/LiteraryFiction.svg';
import defaultbook from './icons/genreImage/defaultbook.svg';
import Foodcooking from './icons/genreImage/Foodcooking.svg';   
import Truecrime from './icons/genreImage/Truecrime.svg';
import Humor from './icons/genreImage/Humor.svg';
import Health from './icons/genreImage/Health.svg';
import lgbtq from './icons/genreImage/lgbtq.svg';
import Erotica from './icons/genreImage/Erotica.svg';
import Western from './icons/genreImage/Western.svg';
import Regency from './icons/genreImage/Regency.svg';
import Paranormal from './icons/genreImage/Paranormal.svg'; 
import Literary from './icons/genreImage/Literary.svg';

export const fictionGenreList = [
    {
        genre: 'Mystery/Crime',
        genre_image: mysteryCrime,
        selected: false,
    },
    {
        genre: 'Thriller',
        genre_image: Thriller,
        selected: false,
    },
    {
        genre: 'Historical Fiction',
        genre_image: HistoricalFiction,
        selected: false,
    },
    {
        genre: 'Contemporary Romance',
        genre_image: ContemporaryRomance,
        selected: false,
    },
    {
        genre: 'Paranormal Romance',
        genre_image: Paranormalromance,
        selected: false,
    },
    {
        genre: 'Period Romance',
        genre_image: PeriodRomance,
        selected: false,
    },
    {
        genre: 'Horror',
        genre_image: Horror,
        selected: false,
    },
    {
        genre: 'Sci-Fi',
        genre_image: SciFi,
        selected: false,
    },
    {
        genre: 'Fantasy',
        genre_image: Fantasy,
        selected: false,
    },
    {
        genre: 'YA',
        genre_image: YA,
        selected: false,
    },
    {
        genre: 'General Fiction',
        genre_image: generalfiction,
        selected: false,
    },
    {
        genre: 'LGBTQ+', // LGBTQ+
        genre_image: lgbtq,
        selected: false,
    },
    {
        genre: 'Erotica', // Erotica
        genre_image: Erotica,
        selected: false,
    },
    {
        genre: 'Western', // Western
        genre_image: Western,
        selected: false,
    },
    {
        genre: 'Regency', // Regency
        genre_image: Regency,
        selected: false,
    },
    {
        genre: 'Paranormal', // Paranormal
        genre_image: Paranormal,
        selected: false,
    },
    {
        genre: 'Literary', // Literary
        genre_image: Literary,
        selected: false,
    },
];

// create a list of non-fiction genres
export const nonFictionGenreList = [
    {
        genre: 'Biography / Memoir',
        selected: false,
        genre_image: Memoir1,
    },
    {
        genre: 'Current Events / Politics',
        selected: false,
        genre_image: Academic,
    },
    {
        genre: 'Crafts / Hobbies',
        selected: false,
        genre_image: Collectibles,
    },
    {
        genre: 'Sports',
        selected: false,
        genre_image: SportsHobbies,
    },
    {
        genre: 'Self-Help / Wellness',
        selected: false,
        genre_image: Selfhelp,
    },
    {
        genre: 'Games',
        selected: false,
        genre_image: GamesGaming,
    },
    {
        genre: 'Religion / Spirituality',
        selected: false,
        genre_image: Religion,
    },
    {
        genre: 'Home & Lifestyle',
        selected: false,
        genre_image: HomeLifestyle,
    },
    {
        genre: 'Travel',
        selected: false,
        genre_image: Travel,
    },
    {
        genre: 'Popular Science',
        selected: false,
        genre_image: PopularScience,
    },
    {
        genre: 'Parenting',
        selected: false,
        genre_image: Parenting,
    },
    {
        genre: 'History',
        selected: false,
        genre_image: History,
    },
    {
        genre: 'Business',
        selected: false,
        genre_image: Business,
    },
    {
        genre: 'Nature & Climate',
        selected: false,
        genre_image: NatureClimate,
    },
    {
        genre: 'General Non-Fiction',
        selected: false,
        genre_image: generalnonfiction,
    },
    {
        genre: 'Food / Cooking',
        selected: false,
        genre_image: Foodcooking,
    },
    {
        genre: 'True Crime',
        selected: false,
        genre_image: Truecrime,
    },
    {
        genre: 'Humor',
        selected: false,
        genre_image: Humor,
    },
    {
        genre: 'Health',
        selected: false,
        genre_image: Health,
    },
];


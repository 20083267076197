import React, { useState } from 'react';
import data from './wordCloudsData.json';
import styles from './WordCloudComponent.module.css';
import RegenerateIcon from '../../icons/regenerate.png';

const WordCloudComponent = ({ genre, addKeyword, selectedKeywords }) => {
  const [customKeyword, setCustomKeyword] = useState('');

  const keywords = data[genre];

  // Function to get a random selection of 15 words
  const getRandomWords = () => {
    if (!keywords || keywords.length === 0) {
      return [];
    }
    // Shuffle the array and take the first 15 words
    const shuffled = [...keywords].sort(() => 0.5 - Math.random());
    return shuffled.slice(0, 15);
  };

  // State to store the random selection of words
  const [randomKeywords, setRandomKeywords] = useState(getRandomWords);

  // Handler to regenerate random words on button click
  const regenerateWords = () => {
    setRandomKeywords(getRandomWords());
  };

  const handleKeyDown = (event) => {
    // Check if the Enter key (key code 13) is pressed
    if (event.key === 'Enter') {
      event.preventDefault(); // Prevent the default behavior (form submission, etc.)
      if (customKeyword.trim().length > 100) {
        alert('Keyword is too long');
        return;
      }
      // Check if the input is not empty
      if (customKeyword.trim()) {
        addKeyword(genre, customKeyword);
        setCustomKeyword('');
      }
    }
  };

  return (
    <div>
      <div className={styles.keywordContainer}>
        {randomKeywords.length > 0 ? (
          <>
            {randomKeywords
              .filter((keyword) => !selectedKeywords.includes(keyword))
              .map((keyword, index) => (
                <span
                  className={styles.keyword}
                  key={index}
                  onClick={() => {
                    addKeyword(genre, keyword);
                  }}
                >
                  {keyword}
                </span>
              ))}
            <span className={styles.ownKeyword} key={`your-own`}>
              <input
                type="text"
                placeholder="+ Add your own"
                value={customKeyword}
                onChange={(e) => setCustomKeyword(e.target.value)}
                onKeyDown={handleKeyDown}
                className={styles.ownKeywordInput}
              ></input>
            </span>

            <div className={styles.regenerate}>
              <img
                className={styles.regenerateIcon}
                src={RegenerateIcon}
                alt="regenerate"
                onClick={regenerateWords}
              />
              <div className={styles.regenerateText} onClick={regenerateWords}>
                Regenerate
              </div>
            </div>
          </>
        ) : (
          <>
            <div className={styles.noneToSuggest}>None to suggest</div>
            <span className={styles.ownKeyword} key={`your-own`}>
              <input
                type="text"
                placeholder="+ Add your own"
                value={customKeyword}
                onChange={(e) => setCustomKeyword(e.target.value)}
                onKeyDown={handleKeyDown}
                className={styles.ownKeywordInput}
              ></input>
            </span>
          </>
        )}
      </div>
    </div>
  );
};

export default WordCloudComponent;

import styles from './Reject.module.css';
import { useRecoilState } from 'recoil';
import {
  companyInfoState,
  userInfoState,
  clickedManuscriptAtom,
  scoreDataAtom,
  pendingProcessingState,
  manualLoginState
} from '../../../state';
import { alpha, styled } from '@mui/material/styles';
import { pink } from '@mui/material/colors';
import Switch from '@mui/material/Switch';
import x from '../../../icons/vuesax/outline/addx.svg';
import useApiUtils from '../../../useApiUtils';
import { useState, useEffect } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import { renderCenteredAlert } from '../../../components/CenteredAlert';

const Reject = (props) => {
  const [clickedManuscript, setClickedManuscript] = useRecoilState(
    clickedManuscriptAtom
  );
  const [manualLogin, setManualLogin] = useRecoilState(manualLoginState);

  const [pendingProcessing, setPendingProcessing] = useRecoilState(
    pendingProcessingState
  );
  const [userInfo, setUserInfo] = useRecoilState(userInfoState); // Update the path
  const [companyInfo, setCompanyInfo] = useRecoilState(companyInfoState);
  const [scoreData, setScoreData] = useRecoilState(scoreDataAtom);

  const [loadingChangeAutoEmail, setLoadingChangeAutoEmail] = useState(false);
  const [isRejected, setIsRejected] = useState(false);
  const [undo, setUndo] = useState(false);
  const [authorEnailInputt, setAuthorEnailInputt] = useState(clickedManuscript.author_email);
  const [isEmailChanged, setIsEmailChanged] = useState(false);
  const [iscontentChanged, setIscontentChanged] = useState(false);

  const {
    getManuscriptsInfo,
    initialgetBookChapter,
    get_book_scores,
    getbookgenrekeywords,
    sendRejection,
    sendApprove,
    editusersettings,
    EditmanuscriptApi,
    // Add other functions from the hook if needed
  } = useApiUtils();

  const handleOut = () => {
    // if(isRejected){
    //     setUndo(true);
    //     sendRejection( clickedManuscript.book_id);
    //     props.setUserRejected(false);
    //     setIsRejected(false);
    //     props.handleReject(false);
    // }

    props.handleReject(false);
  };

  const [rejectText, setRejectText] = useState('');

  useEffect(() => {
    const fullContent = userInfo.rejection_template.content;
    const authorNamePlaceholder = '{{Author name}}';
    const companyNamePlaceholder = '{{Company Name}}';

    const observationsPlaceholder = '{{observations}}';

    let contentWithCompanyName = fullContent.replace(
      companyNamePlaceholder,
      companyInfo.company_name || 'Unknown Company'
    );

    let contentWithAuthorName = contentWithCompanyName.replace(
      authorNamePlaceholder,
      clickedManuscript.author_name || 'Unknown recipient'
    );

    let observationsText = ''; // Default to empty string if no specific feedback is available

    // Only try to replace observations placeholder if scoreData.lowest_score is defined and has elements
    if (scoreData.lowest_score && scoreData.lowest_score.length > 0) {
      observationsText =
        "We'd like to offer you the following feedback provided to us by our Acquisitions team. We hope it provides you with some direction and would encourage you to resubmit again in a few months.\n\n• " +
        scoreData.lowest_score[0];
      if (scoreData.lowest_score.length > 1) {
        observationsText += '\n• ' + scoreData.lowest_score[1]; // Add the second observation if available
      }
    }

    let contentWithObservations = contentWithAuthorName.replace(
      observationsPlaceholder,
      observationsText
    );

    setRejectText(contentWithObservations);

    if (userInfo.user_config?.send_decline_email) {
      setChecked(true);
    }

    // }
  }, [userInfo, clickedManuscript]);

  const handleUndoReject = () => {
    setUndo(true);
    setIsRejected(false);
    props.handleReject(false);
  };

  const Editmanuscript = async (authorEmailInput) => {
    setPendingProcessing(1);

    let genresToreturn = [];

    if (
      clickedManuscript.genres === null ||
      clickedManuscript.genres === 'none'
    ) {
      genresToreturn = [];
    } else {
      genresToreturn = clickedManuscript.genres;
    }

    const requestPayload = {
      user_id: userInfo.user_id,
      edit_manuscript_info: [
        {
          book_id: clickedManuscript.book_id,
          manuscript_name: clickedManuscript.manuscript_name,
          author_name: clickedManuscript.author_name,
          genres: genresToreturn,
          author_email:
            authorEmailInput !== ''
              ? authorEmailInput
              : clickedManuscript.author_email,
        },
      ],
    };
    await EditmanuscriptApi(requestPayload);
  };

  const handlerejectbefore = async () => {
    if (isEmailChanged) {
      // validate the email
      const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
      if (!emailRegex.test(authorEnailInputt)) {
        renderCenteredAlert('Invalid email address');
        return;
      }
      await Editmanuscript(authorEnailInputt);
    }
    sendRejection(clickedManuscript.book_id, iscontentChanged, rejectText);
    props.return(false);
    props.handleReject(false);
  };

  const currentDate = new Date();
  const currentTime = currentDate.toLocaleTimeString('en-US', {
    hour12: false,
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
  });
  const formattedDateTime =
    currentDate.toISOString().split('T')[0] + ' ' + currentTime;

  const PinkSwitch = styled(Switch)(({ theme }) => ({
    '& .MuiSwitch-switchBase': {
      color: 'white', // Knob color when unchecked
      '&:hover': {
        backgroundColor: alpha(
          theme.palette.common.white,
          theme.palette.action.hoverOpacity
        ),
      },
      '&.Mui-checked': {
        color: '#1CEAA0', // Knob color when checked
        '&:hover': {
          backgroundColor: alpha(pink[600], theme.palette.action.hoverOpacity),
        },
        '+ .MuiSwitch-track': {
          backgroundColor: '#a1e0c9', // Track color when checked
        },
      },
    },
    '& .MuiSwitch-track': {
      backgroundColor: 'white', // Track color when unchecked
    },
  }));

  const [checked, setChecked] = useState(false);
  const handleChange = async (event) => {
    if (loadingChangeAutoEmail) {
      return;
    }
    setLoadingChangeAutoEmail(true);
    setChecked(event.target.checked);
    const requestPayload = {
      user_id: userInfo.user_id,
      send_decline_email: event.target.checked,
    };
    //send upadte to the server
    await editusersettings(requestPayload);
    //update the local state
    setUserInfo({
      ...userInfo,
      user_config: {
        ...userInfo.user_config,
        send_decline_email: event.target.checked,
      },
    });
    setLoadingChangeAutoEmail(false);
  };

  const delayDays =
    userInfo?.rejection_template.decline_email_delay_days || 0;

  // CalculatuserInfoe the future date
  const futureDate = new Date();
  futureDate.setDate(futureDate.getDate() + delayDays);

  // Format the future date as an ISO string and extract the date part
  const formattedFutureDate = futureDate.toISOString().split('T')[0];

  // const { img, title, outher, emailText } = props;
  return (
    <div className={manualLogin ? styles.manualbackdrop : styles.backdrop}>
      {/* <div className={styles.container}> */}
      <div className={styles.TopSection}>
        <div className={styles.buttonOut} onClick={handleOut}>
          <img className={styles.xbutton} src={x} />
          {/* <div className={styles.xbutton}>X</div> */}
        </div>

        <div className={styles.MiddleSection}>
          <div className={styles.ImageContainer}>
            <img
              className={styles.Image}
              src={clickedManuscript.image}
              alt=""
            />
          </div>
          <div className={styles.title}>
            {clickedManuscript.manuscript_name}
          </div>
          {clickedManuscript.author_name &&
            clickedManuscript?.author_name !== null &&
            clickedManuscript?.author_name !== ' ' && (
              <div className={styles.outherName}>
                By{' '}
                <div className={styles.outher}>
                  {clickedManuscript.author_name}
                </div>
              </div>
            )}
        </div>
        <div className={styles.subMiddleSection}>
          <div className={styles.ConfirmationTitle}>
            Are You Sure You Want to Decline This Manuscript?
          </div>
          {!checked && (
            <div className={styles.ConfirmationSubtitle}>
              No decline email will be sent
            </div>
          )}
          {checked && (
            <div className={styles.ConfirmationSubtitle}>
              If you decline this manuscript, the following email will be sent
              to the author on {formattedFutureDate}
            </div>
          )}
          <div className={styles.enable_auto}>
            {!loadingChangeAutoEmail && (
              <PinkSwitch
                checked={checked}
                size="small"
                onChange={handleChange}
                inputProps={{ 'aria-label': 'controlled' }}
              />
            )}
            {
              loadingChangeAutoEmail && (
                //    <button className={styles.buttonDownload}>
                <CircularProgress style={{ color: '#1CEAA0' }} size={20} />
              )
              //    </button>
            }
            <div className={styles.ConfirmationSubtitle}>
              Send email when Declining{' '}
            </div>
          </div>
          {checked && (
            <div className={styles.EmailBody}>
              <div className={styles.authoremail}>
                <div className={styles.toSubtitle}>Email:</div>
                <div className={styles.EmailText}>
                  <input
                    className={styles.EditablemailText}
                    defaultValue={authorEnailInputt}
                    value={authorEnailInputt}
                    // onchange set the author email and setIsEmailChanged  to true
                    onChange={(e) => {
                      setAuthorEnailInputt(e.target.value);
                      setIsEmailChanged(true);
                    }}
                  />
                </div>
              </div>
              <div className={styles.EmailText}>
                {/* <textarea
                                    className={styles.EditablesubjectText}
                                    defaultValue={"subject: "}
                                // any additional props you need
                                /> */}
                <textarea
                  className={styles.EditableRejectText}
                  defaultValue={rejectText}
                  onChange={(e) => {
                    setIscontentChanged(true);
                    setRejectText(e.target.value);
                  }}
                // any additional props you need
                />
              </div>
            </div>
          )}
        </div>
      </div>
      {!isRejected && (
        <div className={styles.ButtonSection}>
          <div className={styles.NoButtonContainer} onClick={handleOut}>
            <div className={styles.noButton}>No, Don’t Decline</div>
          </div>
          <div
            className={styles.YesButtonContainer}
            onClick={handlerejectbefore}
          >
            <div className={styles.yesButton}>Yes, Decline</div>
          </div>
        </div>
      )}
      {isRejected && (
        <div className={styles.ButtonSection}>
          <div className={styles.YesButtonContainer} onClick={handleOut}>
            <div className={styles.yesButton}>Declined</div>
          </div>
          <div className={styles.NoButtonContainer} onClick={handleUndoReject}>
            <div className={styles.noButton}>Undo</div>
          </div>
        </div>
      )}
    </div>
    // </div>
  );
};

export default Reject;

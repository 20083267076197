import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import styles from './SetWishlist.module.css';
import UncoverLeft from './UncoverLeft';
import Line from '../..//icons/AUTH/signin/Line 2.svg';
import Line2 from '../..//icons/AUTH/signin/Line 3.svg';
import back from '../..//icons/back.svg';
import step2 from '../..//icons/AUTH/signin/curentstep2.svg';
import step3 from '../..//icons/AUTH/signin/step3.svg';
import { Link } from 'react-router-dom';

import men from '../..//icons/AUTH/signin/men.svg';
import WishlistAccordion from '../propileInfo/WishlistAccordion';

export default function SetWishlist() {
  const navigate = useNavigate();
  const location = useLocation();

  const [superadmin, setSuperadmin] = useState(false);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const superadmin = queryParams.get('superadmin');
    if (superadmin === 'true') {
      setSuperadmin(true);
    }
  }, []);

  const handleBackClick = () => {
    navigate(`/set-preferences${location.search}`);
  };

  const handleNextClick = async () => {
    navigate(`/user-manual-decline${location.search}`);
  };

  return (
    <div className={styles.container}>
      <UncoverLeft />
      <div className={styles.logosignincontainer}>
        <div className={styles.signcontainer}>
          <div className={styles.hedder}>
            <div className={styles.steps}>
              <div className={styles.step1}>
                <div className={styles.step1circle}>
                  <img src={men} className={styles.men} />
                </div>
              </div>
              <div className={styles.line1}>
                <div className={styles.line1img}>
                  <img src={Line2} alt="line" />
                </div>
              </div>
              <div className={styles.step1}>
                <div className={styles.step2circle}>
                  <img src={step2} className={styles.menstep2} />
                </div>
                <div className={styles.step1text}>
                  step 2/3
                  <div className={styles.step1text1}>Set Your Preferences</div>
                </div>
              </div>
              <div className={styles.line1}>
                <div className={styles.line1img}>
                  <img src={Line} alt="line" />
                </div>
              </div>
              <div className={styles.step3}>
                <div className={styles.step3circle}>
                  <img src={step3} className={styles.menstep3} />
                </div>
              </div>
            </div>

            <div className={styles.signup}>
              <div className={styles.backtologin}>Already have an account?</div>
              <Link to="/" className={styles.linkto}>
                Log in
              </Link>
            </div>
          </div>
          <div className={styles.body}>
            <div className={styles.titlelogo}>
              <div className={styles.loginparttitle}>
                Set your taste preferences{' '}
              </div>
            </div>

            <WishlistAccordion />
          </div>
          <div className={styles.fotter}>
            <div className={styles.pagenumber}>
              <div className={styles.pagenumberfirst}>3</div>/
              {superadmin ? '6' : '4'}
            </div>
            <div className={styles.buttoncontainer}>
              <div className={styles.buttoncontainerback}>
                <img
                  src={back}
                  className={styles.back}
                  onClick={handleBackClick}
                />
                <div
                  type="submit"
                  className={styles.buttonBack}
                  onClick={handleBackClick}
                >
                  <div className={styles.textbuttonBack}>Back</div>
                </div>
              </div>
              <div
                type="submit"
                className={`${styles.button}`}
                onClick={handleNextClick}
              >
                <div className={styles.textbutton}>Next</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

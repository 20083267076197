import styles from './ButtonMenuscript.module.css';
import reject from '../../../icons/reject.svg';
import approve from '../../../icons/approve.svg';
import rejectB from '../../../icons/rejectB.svg';
import approveB from '../../../icons/approveB.svg';
import { useState } from 'react';
import { useEffect } from 'react';
import useApiUtils from '../../../useApiUtils';
import { useRecoilState } from 'recoil';
import {
  showChatState,
  emailToApproveState,
  pendingProcessingState,
  clickedManuscriptAtom,
  userInfoState,
} from '../../../state';
import bot from '../../../icons/botchat.svg';

const ButtonMenuscript = (props) => {
  const [emailToApprove, setEmailToApprove] =
    useRecoilState(emailToApproveState);
  const [userInfo, setUserInfo] = useRecoilState(userInfoState); // Update the path
  const [showChat, setShowChat] = useRecoilState(showChatState);

  const [statuschangedD, setStatusChangedD] = useState(false);
  const [statuschangedA, setStatusChangedA] = useState(false);
  const [isRejected, setIsRejected] = useState(false);
  const [isApproved, setIsApproved] = useState(false);

  const [clickedManuscript, setClickedManuscript] = useRecoilState(
    clickedManuscriptAtom
  );
  const [undo, setUndo] = useState(false);
  const [ondoApprove, setUndoApprove] = useState(false);
  const [pendingProcessing, setPendingProcessing] = useRecoilState(
    pendingProcessingState
  );
  const {
    getManuscriptsInfo,
    initialgetBookChapter,
    get_book_scores,
    getbookgenrekeywords,
    sendRejection,
    sendApprove,
    // Add other functions from the hook if needed
  } = useApiUtils();

  const handleReject = () => {
    props.onReject(true);
  };

  useEffect(() => {
    if (props.userApproved) {
      handleManuscriptApproved();
    }
    if (props.userRejected) {
      handleManuscriptRejected();
    }
  }, [clickedManuscript]);

  const handleApprove = () => {
    props.onApprove(true);
    // setIsApproved(true);
    // setTimeout(() => {
    //     setIsApproved(false);
    // }, 50000); // 50 seconds
  };

  const handleUndoReject = () => {
    setUndo(true);
    props.onReject(false);
    setIsRejected(false);
  };

  const handleManuscriptRejected = () => {
    setUndo(true);

    sendRejection(clickedManuscript.book_id);
    props.setUserRejected(false);
    setIsRejected(false);
    // props.onReject(false);
    // setIsRejected(false);
  };

  const handleUndoApprove = () => {
    setUndoApprove(true);
    props.onApprove(false);
    setIsApproved(false);
  };

  const handleManuscriptApproved = () => {
    setUndoApprove(true);
    sendApprove(clickedManuscript.book_id, emailToApprove);

    props.setUserApproved(false);
    setIsApproved(false);
    props.onApprove(false);
  };

  const handleChat = () => {
    setShowChat(!showChat);
  };

 
  return (
    <>
      {/* {!isRejected && !isApproved && ( */}
      <div className={styles.FrameRootRoot}>
            <button className={styles.ButtonReject} onClick={handleReject}>
              <div className={styles.Dislike}>
                <img className={styles.hand} src={reject} />
                <img className={styles.handB} src={rejectB} />
              </div>
              <div className={styles.rejectText}>Decline</div>
            </button>
              <button className={styles.ButtonApprove} onClick={handleApprove}>
                <div className={styles.Like}>
                  <img className={styles.handa} src={approve} />
                  <img className={styles.handaB} src={approveB} />
                </div>
                <div className={styles.approveText}>Approve</div>
              </button>
        <div>
          <img src={bot} className={styles.bot} onClick={() => handleChat()} />
        </div>
      </div>
      {/* )} */}
      {isRejected && (
        <div className={styles.FrameRootRootManuscriptRejected}>
          <button
            className={styles.ButtonManuscriptRejected}
            onClick={handleManuscriptRejected}
          >
            <div className={styles.ManuscriptRejectedText}>
              Manuscript Declined
            </div>
          </button>
          <button className={styles.ButtonUndo} onClick={handleUndoReject}>
            <div className={styles.UndoText}>Undo</div>
          </button>
        </div>
      )}
      {isApproved && (
        <div className={styles.FrameRootRoot}>
          <button
            className={styles.ButtonManuscriptRejected}
            onClick={handleManuscriptApproved}
          >
            <div className={styles.ManuscriptApprovedText}>
              Manuscript Approved
            </div>
          </button>
          <button className={styles.ButtonUndo} onClick={handleUndoApprove}>
            <div className={styles.UndoText}>Undo</div>
          </button>
        </div>
      )}
    </>
  );
};

export default ButtonMenuscript;

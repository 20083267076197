import styles from './ApproveManuscript.module.css';
import { useState, useEffect, useRef } from 'react';
import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';
import Select from 'react-select';
import {
  emailToApproveState,
  clickedManuscriptAtom,
  currentManuscriptAtom,
  notesAtom,
  userInfoState,
} from '../../../state';
import { useRecoilState } from 'recoil';
import useApiUtils from '../../../useApiUtils';
import AvatarCostom from '../../../UI/AvatarCostom';
import CircularProgress from '@mui/material/CircularProgress';
import { renderCenteredAlert } from '../../CenteredAlert';

const ApproveManuscript = (props) => {
  const [clickedManuscript, setClickedManuscript] = useRecoilState(
    clickedManuscriptAtom
  );
  const [saveButtonState, setSaveButtonState] = useState('normal'); // 'normal', 'saving', 'success', 'failure'

  const {

    sendApprove,
    // Add other functions from the hook if needed
  } = useApiUtils();



 

  //i want to detect click outside of the deletebox
  const approveboxRef = useRef(null);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        approveboxRef.current &&
        !approveboxRef.current.contains(event.target)
      ) {
        props.return(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [approveboxRef]);

  const handelbutton = () => {
    props.return(false);
  };

  const handelbuttonApproved = async () => {

    if (saveButtonState === 'loading') {
      return;
    }
    setSaveButtonState('loading');


    await sendApprove(clickedManuscript.book_id);
    setSaveButtonState('normal');

    props.return(false);
  };

  const getButtonText = () => {
    switch (saveButtonState) {
      case 'saving':
        return 'Saving...';
      case 'success':
        return 'Saved Successfully';
      case 'failure':
        return 'Failed to Save';
      case 'loading':
        return <CircularProgress size={15} color="inherit" />;
      default:
        return 'Yes, Approve';
    }
  };


  


  

  return (
    <div className={styles.backdrop}>
      <div className={styles.container} ref={approveboxRef}>
        <div className={styles.TopSection}>
          <div className={styles.titleTEXT}>Approve Manuscript?</div>
          <div className={styles.buttonOut}>
            <button className={styles.xbutton} onClick={handelbutton}>
              X
            </button>
          </div>
        </div>
        <div className={styles.MiddleSectionall}>
          <div className={styles.MiddleSection}>
            <div className={styles.ImageContainer}>
              <img
                className={styles.Image}
                src={clickedManuscript.image}
                alt=""
              />
            </div>
            <div className={styles.title}>
              {clickedManuscript.manuscript_name}
            </div>
            <div className={styles.outherName}>
              By{' '}
              <div className={styles.outher}>
                {clickedManuscript.author_name}
              </div>
            </div>
          </div>
          <div className={styles.Confirmationsection}>
            <div className={styles.ConfirmationTitle}>
              Are You Sure You Want to Approve This Manuscript?
            </div>
            <div className={styles.ConfirmationSubtitle1}>
            The manuscript status on your dashboard will be updated to Approved.
            The author will not be notified.
            </div>
          </div>
        </div>
        <div className={styles.ButtonSection}>
          <div className={styles.YesButtonContainer} onClick={handelbutton}>
            <div className={styles.noButton}>No, Don’t Approve </div>
          </div>
          <div
            className={`${styles.NoButtonContainer}${saveButtonState === 'success'
                ? styles.successButton
                : saveButtonState === 'failure'
                  ? styles.failureButton
                  : ''
              }`}
            onClick={handelbuttonApproved}
          >
            <div className={styles.yesButton}>{getButtonText()}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ApproveManuscript;

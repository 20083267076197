
import styles from './CompanyAutoDecline.module.css';


import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { alpha, styled } from '@mui/material/styles';
import { pink } from '@mui/material/colors';
import Switch from '@mui/material/Switch';

import { useRecoilState } from 'recoil';
import { companyInfoState, userInfoState } from '../../state';
import useApiUtils from '../../useApiUtils';
import CircularProgress from '@mui/material/CircularProgress';
import ApproveAD from './ApproveAD';
import { renderCenteredAlert, renderCenteredInfo } from '../CenteredAlert';

import CompanyDecline from './CompanyDecline';


const CompanyAutoDecline = (props) => {


  const [buttonTextNormal, setButtonTextNormal] = useState('Save Changes');
  const [showBackButton, setShowBackButton] = useState(false);
  const [afterSave, setAfterSave] = useState(false);
  const navigate = useNavigate();



  return (
    <div className={styles.logosignincontainer}>
      <CompanyDecline
        buttonTextNormal={buttonTextNormal} setButtonTextNormal={setButtonTextNormal}
        showBackButton={showBackButton} 
        afterSave={afterSave} setAfterSave={setAfterSave} />
    </div>
  );
  
};

export default CompanyAutoDecline;

import React, { useEffect, useRef } from 'react';
import { useRecoilState, useResetRecoilState } from 'recoil';
import styles from './DiscoverPage.module.css';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Header from '../header/header';
import { userInfoState } from '../../state';
import useApiUtils from '../../useApiUtils';
import CircularProgress from '@mui/material/CircularProgress';
import Markdown from 'markdown-to-jsx';
import { FaPaperPlane } from 'react-icons/fa';
import LoadingDots from './LoadingDots';
import { useRecoilValue } from 'recoil';
import {
  copilotStateAtom,
  manuscriptInfoListAtom,
  clickedManuscriptAtom,
  authorInfoState,
  synopsisState,
  readerViewState,
  scoreDataAtom,
  most_similar_bookAtom,
  bookTasteInfoState,
} from '../../state';
import { Card, CardContent, Typography, Grid } from '@mui/material';
import BookBlock from './BookBlock';
import AboutBook from '../submission/popupBook/AboutBook';
import { set } from 'date-fns';
import { FaTrashAlt, FaRegEdit } from 'react-icons/fa';
import { ImSpinner2 } from 'react-icons/im';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
} from '@mui/material';
import { renderCenteredInfo } from '../CenteredAlert';
import betaIcon from '../../icons/beta.png';

const SearchPage = () => {
  const copilotState = useRecoilValue(copilotStateAtom);
  const [manuscriptInfoList, setManuscriptInfoList] = useRecoilState(
    manuscriptInfoListAtom
  );
  const [clickedManuscript, setClickedManuscript] = useRecoilState(
    clickedManuscriptAtom
  );

  const [authorInfo, setAuthorInfo] = useRecoilState(authorInfoState);
  const [synopsis, setSynopsis] = useRecoilState(synopsisState);
  const [scoreData, setScoreData] = useRecoilState(scoreDataAtom);
  const [most_similar_book, setMost_similar_book] = useRecoilState(
    most_similar_bookAtom
  );
  const [readerView, setReaderView] = useRecoilState(readerViewState);

  const [bookTasteInfo, setBookTasteInfo] = useRecoilState(bookTasteInfoState);

  const [query, setQuery] = useState('');

  const [error, setError] = useState(null);

  const [chatHistoryError, setChatHistoryError] = useState(null);

  const [loading, setLoading] = useState(false);
  const [loadingThreadHistory, setLoadingThreadHistory] = useState(false);

  const [userInfo, setUserInfo] = useRecoilState(userInfoState);

  const [selectedValue, setSelectedValue] = useState('');

  const [showBookinfo, setShowBookInfo] = useState(false);

  const [showSuccess, setShowSuccess] = useState(false);

  const [currentThreadId, setCurrentThreadId] = useState(0);

  const [showPopup, setShowPopup] = useState(false);

  const [isDeleting, setIsDeleting] = useState(false);

  // Function to find a thread by its ID
  const findThreadById = (id) => {
    return copilotState.threads.find((thread) => thread.id === id);
  };

  const thread = findThreadById(currentThreadId);

  const quickSearches = [
    'Find romance books with a tragic end',
    'What are the main themes in my slushpile',
    'Historical fiction with a young adult theme',
    'Novel books with a humble hero',
  ];

  const {
    getChatHistory,
    chatManuscripts,
    deleteCopilotThread,
    get_book_scores,
    fetchReaderViewNotes,
    get_most_similar_books,
    get_autor_info,
    get_synopsis,
    get_book_taste_info,
  } = useApiUtils();

  useEffect(() => {
    const loadThreadHistory = async () => {
      console.log('loading thread history');
      setLoadingThreadHistory(true);

      try {
        const apiResults = await getChatHistory();
        setLoadingThreadHistory(false);

        if (apiResults.error) {
          setChatHistoryError(apiResults.error);
        }
      } catch (error) {
        setLoadingThreadHistory(false);
        setChatHistoryError(error.message); // Handle any other errors
      }
    };

    loadThreadHistory();

    return () => {
      // Add any cleanup logic here if necessary
    };
  }, []);

  const navigate = useNavigate();

  const handleSearch = async (qs_query) => {
    if (loading) return;

    setError('');

    // sometimes called with quicksearch in which case the query is passed as an argument
    const searchQuery = qs_query || query;

    if (!searchQuery) {
      setError('Please enter a search query');
      return;
    }

    setQuery('');
    setLoading(true);
    if (!currentThreadId) {
      setCurrentThreadId('new_thread');
    }

    const apiResults = await chatManuscripts(
      searchQuery,
      userInfo.company_id,
      thread ? thread.id : null
    );
    setLoading(false);

    if (apiResults.error) {
      setLoading(false);
      setError(apiResults.error);
      return;
    } else {
      setCurrentThreadId(apiResults.threadId);
      setLoading(false);
    }
  };

  const handleQuickSearch = async (qs_query) => {
    if (loading) return;
    setQuery(qs_query);
    handleSearch(qs_query);
  };

  const handleNewThread = () => {
    if (loading) return;
    setCurrentThreadId(null);
  };

  const handleLoadThread = (threadId) => {
    if (loading) return;
    console.log('loadig threadId:', threadId);
    setCurrentThreadId(threadId);
  };

  const findBookById = (bookId) => {
    // console.log('bookId:', bookId);
    // console.log('manuscriptInfoList:', manuscriptInfoList);
    return manuscriptInfoList.find((book) => book.book_id === bookId);
  };

  const bookCardClick = (book) => {
    console.log('book:', book);
    setClickedManuscript(book);
    setShowBookInfo(true);

    setAuthorInfo(null);
    setSynopsis(null);
    setBookTasteInfo(null);
    setScoreData([]);
    setMost_similar_book([]);
    setReaderView([])

    // we kick off the api calls to get the book info for the about book popup
    get_book_scores(book.book_id);
    fetchReaderViewNotes(book.book_id);
    get_most_similar_books(book.book_id);
    get_autor_info(book.book_id);
    get_synopsis(book.book_id);
    get_synopsis(book.book_id);
    get_book_taste_info(book.book_id, book.taste_match);
  };

  // used for showing the success message after downloading
  const showSuccessMessage = (message) => {
    setShowSuccess(true);
    setTimeout(() => {
      setShowSuccess(false);
    }, 5000);
  };

  useEffect(() => {
    if (!localStorage.getItem('id_token')) {
      navigate('/');
    }
  }, []);

  const handleDeleteClick = (e) => {
    e.stopPropagation(); // Prevents the thread click from triggering
    setShowPopup(true);
  };

  const confirmDelete = async () => {
    setShowPopup(false);
    setIsDeleting(true);
    await deleteCopilotThread(currentThreadId);
    setIsDeleting(false);
    renderCenteredInfo('Thread deleted successfully');
    //reset the UI to show a new thread
    setCurrentThreadId(0);
  };

  const cancelDelete = () => {
    setShowPopup(false);
  };

  return (
    <div className={styles.submissionsPageContainer}>
      <Header issearch={true} issettings={false} />

      <div className={styles.submissionsPageC}>
        <div className={styles.submissionsPage}>
          <div className={styles.submissionsPageTitle}>
            Discover <img src={betaIcon} width={50} />
          </div>
          <div className={styles.allManuscripyPart2}></div>
        </div>
        {/* <div className={styles.progress}> */}
        <div className={styles.pageContainer}>
          {showBookinfo && (
            <AboutBook
              key={`discover-book-info-${clickedManuscript.book_id}`}
              id_manuscript={1}
              return={setShowBookInfo}
              showSuccessMessage={showSuccessMessage}
            />
          )}
          <div className={styles.leftSidebar}>
            {' '}
            <div
              className={styles.buttonNewThread}
              onClick={() => handleNewThread()}
            >
              <FaRegEdit /> New Thread
            </div>
            {loadingThreadHistory ? (
              <div className={styles.threadsContainer}>
                <div className={styles.singleThread}>Loading...</div>
              </div>
            ) : (
              <div className={styles.threadsContainer}>
                {copilotState.threads.map((thread, index) => (
                  <div
                    className={`${styles.singleThread} ${
                      currentThreadId === thread.id ||
                      'new_thread' === thread.id
                        ? styles.activeThread
                        : ''
                    }`}
                    key={`thread_${index}`}
                    onClick={() => handleLoadThread(thread.id)}
                  >
                    {thread.questions[0]}
                    {(currentThreadId === thread.id ||
                      'new_thread' === thread.id) && (
                      <>
                        {isDeleting ? (
                          <ImSpinner2 className={styles.spinnerIcon} /> // Spinner icon if deleting
                        ) : (
                          <FaTrashAlt
                            className={styles.deleteIcon}
                            onClick={handleDeleteClick} // Delete icon if not deleting
                          />
                        )}
                      </>
                    )}
                  </div>
                ))}
              </div>
            )}
          </div>
          {thread && thread.questions.length > 0 ? (
            // Already inside a chat
            <div className={styles.tableManuscripts}>
              <div className={styles.container}>
                {thread.questions.map((question, index) => (
                  <div key={index} className={styles.qaItem}>
                    <h1 className={styles.heading}>{question}</h1>
                    <div className={styles.answerContainer}>
                      {thread.answers[index] ? (
                        <React.Fragment>
                          {thread.bookIds && thread.bookIds[index] && (
                            <Grid
                              container
                              spacing={2}
                              gap={2}
                              direction="row"
                              wrap="nowrap"
                              sx={{
                                overflowX: 'auto',
                                marginTop: '10px',
                                marginBottom: '10px',
                              }}
                            >
                              {/* using a set to remove duplicates */}
                              {[...new Set(thread.bookIds[index])].map(
                                (bookId, index) => {
                                  const book = findBookById(bookId);
                                  if (book) {
                                    return (
                                      <div
                                        onClick={() => {
                                          bookCardClick(book);
                                        }}
                                      >
                                        <BookBlock
                                          key={index}
                                          title={book.manuscript_name}
                                          time={book.date_uploaded}
                                          image={book.image}
                                          status={book.status}
                                        />
                                      </div>
                                      // <Grid item key={index}>
                                      //   <Card>
                                      //     <CardContent>
                                      //       <Typography
                                      //         variant="h6"
                                      //         component="div"
                                      //       >
                                      //         {book.manuscript_name || 'Untitled'}
                                      //       </Typography>
                                      //       {/* <Typography color="text.secondary">
                                      //         Author:{' '}
                                      //         {book.author_name || 'Unknown'}
                                      //       </Typography>
                                      //       <Typography color="text.secondary">
                                      //         Status: {book.status}
                                      //       </Typography> */}
                                      //       <Typography color="text.secondary">
                                      //         Date Uploaded:{' '}
                                      //         {new Date(
                                      //           book.date_uploaded
                                      //         ).toLocaleDateString()}
                                      //       </Typography>
                                      //     </CardContent>
                                      //   </Card>
                                      // </Grid>
                                    );
                                  }
                                }
                              )}
                            </Grid>
                          )}
                          <h4>Answer:</h4>
                          <Markdown>
                            {thread.answers[index].replace(
                              /【\d+:\d+†[^】]+】/g,
                              ''
                            )}
                          </Markdown>

                          {/* MUI Dialog for Confirm Delete */}
                          <Dialog
                            open={showPopup}
                            onClose={cancelDelete}
                            sx={{
                              '& .MuiDialogTitle-root': {
                                fontFamily: 'Zilla Slab, serif',
                              },
                              '& .MuiDialogContent-root': {
                                fontFamily: 'Lato, serif',
                              },
                            }}
                          >
                            <DialogTitle>Confirm Deletion</DialogTitle>
                            <DialogContent>
                              Are you sure you want to delete this thread?
                            </DialogContent>
                            <DialogActions>
                              <Button
                                onClick={cancelDelete}
                                className={styles.cancelDelete}
                                sx={{
                                  backgroundColor: '#bdbdbd',
                                  color: '#424242',
                                  '&:hover': {
                                    backgroundColor: '#9e9e9e',
                                  },
                                }}
                              >
                                Cancel
                              </Button>
                              <Button
                                onClick={confirmDelete}
                                sx={{
                                  backgroundColor: '#f44336',
                                  color: 'white',
                                  '&:hover': {
                                    backgroundColor: '#d32f2f',
                                    color: 'white',
                                  },
                                }}
                              >
                                Confirm
                              </Button>
                            </DialogActions>
                          </Dialog>
                        </React.Fragment>
                      ) : (
                        <LoadingDots />
                      )}
                    </div>
                  </div>
                ))}

                {!loading && !error && (
                  <div className={styles.searchBoxContainer}>
                    <input
                      type="text"
                      placeholder="Ask a followup question"
                      className={styles.searchBox}
                      onChange={(e) => setQuery(e.target.value)}
                      onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                          handleSearch();
                        }
                      }}
                      value={query}
                    />
                    <div
                      className={styles.buttonSearch}
                      onClick={() => handleSearch()}
                      data-testid="search-button"
                      disabled={loading}
                    >
                      {loading ? (
                        <CircularProgress size={15} color="inherit" />
                      ) : (
                        <FaPaperPlane />
                      )}
                    </div>
                  </div>
                )}

                {/* Display error message */}
                {error && <p style={{ color: 'red' }}>Error: {error}</p>}
              </div>
            </div>
          ) : (
            // Start a new chat
            <div className={styles.tableManuscripts}>
              <div className={styles.container}>
                <h1 className={styles.heading}>What would you like to know?</h1>

                <div className={styles.searchBoxContainer}>
                  {/* <FormControl fullWidth>
                  <InputLabel id="custom-select-label">Context</InputLabel>
                  <Select
                    labelId="custom-select-label"
                    value={selectedValue}
                    onChange={handleChange}
                    label="Select an Option"
                  >
                    
                    <MenuItem value="main">Entire Slushpile</MenuItem>

                    <Divider />

                    <ListSubheader>Specific Book</ListSubheader>
                    <MenuItem value="option1">Harry Potter</MenuItem>
                    <MenuItem value="option2">Hunger Games</MenuItem>
                  </Select>
                </FormControl> */}

                  <input
                    type="text"
                    placeholder="Ask a question or search for a book/s"
                    className={styles.searchBox}
                    onChange={(e) => setQuery(e.target.value)}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        handleSearch();
                      }
                    }}
                    value={query}
                  />
                  <div
                    className={styles.buttonSearch}
                    onClick={() => handleSearch()}
                    data-testid="search-button"
                    disabled={loading}
                  >
                    {loading ? (
                      <CircularProgress size={15} color="inherit" />
                    ) : (
                      <FaPaperPlane />
                    )}
                  </div>
                </div>

                <div className={styles['suggestions-grid']}>
                  {quickSearches.map((text, index) => (
                    <div
                      key={index}
                      className={styles['suggestion-button']}
                      onClick={() => handleQuickSearch(text)}
                    >
                      {text}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          )}
        </div>{' '}
        {/* end pageContainer */}
      </div>
    </div>
  );
};

export default SearchPage;

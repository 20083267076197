import React from 'react';
import { FaStar, FaStarHalfAlt, FaRegStar } from 'react-icons/fa';

// Convert similarity score (0.1-0.5) to a 1-5 star rating
export function convertToStarRating(similarity) {
  const clampedSimilarity = Math.max(0.1, Math.min(0.5, similarity));
  return 1 + ((clampedSimilarity - 0.1) / (0.5 - 0.1)) * 4;
}

export function compareTasteScores(a, b) {
  // Helper function to extract the highest score from a taste_match array
  const getHighestScore = (tasteMatch) => {
    if (!Array.isArray(tasteMatch) || tasteMatch.length === 0) {
      return 0; // Default to 0 if no scores exist
    }
    return Math.max(...tasteMatch.map(([_, score]) => score));
  };

  // Get the highest scores for a and b
  const highestScoreA = getHighestScore(a);
  const highestScoreB = getHighestScore(b);

  // Compare the highest scores
  return highestScoreA - highestScoreB;
}

// Component to display the highest taste score in stars using react-icons
export default function HighestTasteScore({ tasteMatch }) {
  if (tasteMatch.length === 0) {
    return '';
  }

  // Find the genre with the highest taste score
  const [highestGenre, highestScore] = tasteMatch.reduce(
    (max, genre) => (genre[1] > max[1] ? genre : max),
    ['', 0]
  );

  const starRating = convertToStarRating(highestScore);
  const fullStars = Math.floor(starRating);
  const halfStar = starRating % 1 >= 0.5;
  const emptyStars = 5 - fullStars - (halfStar ? 1 : 0);

  return (
    <div>
      {/* <small>Top Genre Match: {highestGenre}</small> */}
      {/* <small>(Score: {highestScore.toFixed(2)})</small> */}
      <div style={{ fontSize: '24px', display: 'flex', alignItems: 'center' }}>
        {/* Full stars */}
        {Array(fullStars)
          .fill(<FaStar color="#1CEAA0" size="15" />)
          .map((star, i) => (
            <span key={`full-${i}`}>{star}</span>
          ))}
        {/* Half star */}
        <span key={`half-${1}`}>
          {halfStar && <FaStarHalfAlt color="#1CEAA0" size="15" />}
        </span>
        {/* Empty stars */}
        {Array(emptyStars)
          .fill(<FaRegStar color="#1CEAA0" size="15" />)
          .map((star, i) => (
            <span key={`empty-${i}`}>{star}</span>
          ))}
      </div>
    </div>
  );
}

// Component to display all genres' taste scores with stars
export function GenreTasteScores({ tasteMatch }) {
  if (tasteMatch.length === 0) {
    return '';
  }

  return (
    <div>
      {tasteMatch.map(([genre, score], index) => {
        const starRating = convertToStarRating(score);
        const fullStars = Math.floor(starRating);
        const halfStar = starRating % 1 >= 0.5;
        const emptyStars = 5 - fullStars - (halfStar ? 1 : 0);

        return (
          <div key={`genre-${index}`} style={{ marginBottom: '10px' }}>
            <small>
              {genre}: {score.toFixed(2)}
            </small>
            <div
              style={{
                fontSize: '24px',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              {/* Full stars */}
              {Array(fullStars)
                .fill(<FaStar color="#1CEAA0" size="15" />)
                .map((star, i) => (
                  <span key={`full-${index}-${i}`}>{star}</span>
                ))}
              {/* Half star */}
              {halfStar && <FaStarHalfAlt color="#1CEAA0" size="14" />}
              {/* Empty stars */}
              {Array(emptyStars)
                .fill(<FaRegStar color="#1CEAA0" size="15" />)
                .map((star, i) => (
                  <span key={`empty-${index}-${i}`}>{star}</span>
                ))}
            </div>
          </div>
        );
      })}
    </div>
  );
}

import styles from './Edit.module.css';
import xxx from '../../../icons/xxx.svg';
import handpoint from '../../../icons/vuesax/bulk/vuesax/bulk/imageuploadI.svg';
import iconiconApload from '../../../icons/iconiconApload.svg';
import { fictionGenreList, nonFictionGenreList } from '../../../genreLists';

import React, {
  useCallback,
  useMemo,
  useState,
  useRef,
  useEffect,
} from 'react';

import { useDropzone } from 'react-dropzone';
import Select from 'react-select'; // Import react-select

import useApiUtils from '../../../useApiUtils';

import { useRecoilState } from 'recoil';
import {
  userInfoState,
  pendingProcessingState,
  clickedManuscriptAtom,
  manuscriptInfoListAtom,
} from '../../../state';


const options = [
  {
    label: 'Fiction',
    options: fictionGenreList.map(({ genre }) => ({ value: genre, label: genre })),
  },
  {
    label: 'Non-Fiction',
    options: nonFictionGenreList.map(({ genre }) => ({ value: genre, label: genre })),
  },
];



const baseStyle = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  // gap: "10px",
  height: '50%',
  width: '100%',
  cursor: 'pointer',
  // padding: "10px",
  // background: "#F7F7F7",
  border: '1px dashed #71858B',
  borderRadius: '12px',
  // flex: "none",
  // order: 0,
  // alignSelf: "stretch",
  // flexGrow: 0,
};

const activeStyle = {
  background: '#F7F7F7',
};

// const acceptStyle = {
//   borderColor: '#00e676'
// };

const rejectStyle = {
  borderColor: '#ff1744',
};

const Edit = (props) => {
  const [clickedManuscript, setClickedManuscript] = useRecoilState(
    clickedManuscriptAtom
  );
  const [manuscriptInfoList, setManuscriptInfoList] = useRecoilState(
    manuscriptInfoListAtom
  );
  const [wasUploaded, setWasUploaded] = useState(false);
  const [imagePreview, setImagePreview] = useState(null);
  const [files, setFiles] = useState([]);
  const [selectedGenres, setSelectedGenres] = useState([]);
  const [pendingProcessing, setPendingProcessing] = useRecoilState(
    pendingProcessingState
  );

  //creat sates for the input fields
  const [manuscriptNameInputt, setManuscriptNameInputt] = useState(
    clickedManuscript.manuscript_name
  );

  const [authorNameInputt, setAuthorNameInputt] = useState(
    clickedManuscript.author_name
  );
  const [authorEnailInputt, setAuthorEnailInputt] = useState(
    clickedManuscript.author_email
  );
  const [userInfo, setUserInfo] = useRecoilState(userInfoState); // Update the path

  const {
    getManuscriptsInfo,
    initialgetBookChapter,
    get_book_scores,
    getbookgenrekeywords,
    handleUploadFileImage,
    EditmanuscriptApi,
    // Add other functions from the hook if needed
  } = useApiUtils();

  //i want to detect click outside of the deletebox
  const editboxRef = useRef(null);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (editboxRef.current && !editboxRef.current.contains(event.target)) {
        props.handleEdit(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [editboxRef]);

  const handleOut = () => {
    props.handleEdit(false);
    setWasUploaded(false);
  };

  const handleSave = () => {
    props.handleEdit(false);
    setWasUploaded(false);
    const manuscriptNameInput = manuscriptNameInputt;
    const authorNameInput = authorNameInputt;
    const authorEmailInput = authorEnailInputt;

    Editmanuscript(manuscriptNameInputt, authorNameInputt, authorEmailInput);
  };

  const Editmanuscript = async (
    manuscriptNameInput,
    authorNameInput,
    authorEmailInput
  ) => {
    let imageUrl = null;
    if (imagePreview !== null) {
      imageUrl = await handleUploadFileImage(files[0]);
    }
    setPendingProcessing(1);

    let genresToreturn = [];
    if (selectedGenres.length > 0) {
      genresToreturn = selectedGenres.map((genre) => genre.value);
    } else {
      if (
        clickedManuscript.genres === null ||
        clickedManuscript.genres === 'none'
      ) {
        genresToreturn = [];
      }
      genresToreturn = clickedManuscript.genres;
    }
    const requestPayload = {
      user_id: userInfo.user_id,
      edit_manuscript_info: [
        {
          book_id: clickedManuscript.book_id,
          manuscript_name:
            manuscriptNameInput !== ''
              ? manuscriptNameInput
              : clickedManuscript.manuscript_name,
          author_name:authorNameInput,
          genres: genresToreturn,
          author_email:authorEmailInput,
          encoded_img_upload_url: imageUrl,
        },
      ],
    };
    EditmanuscriptApi(requestPayload);
  };


  const handleCancel = () => {
    props.handleEdit(false);
    setWasUploaded(false);
  };

  const onDrop = useCallback((acceptedFiles, rejectedFiles) => {
    if (acceptedFiles.length > 0) {
      const file = acceptedFiles[0];

      setFiles([file]);

      const reader = new FileReader();

      reader.onloadend = () => {
        setImagePreview(reader.result);
      };

      if (file) {
        reader.readAsDataURL(file);
      }
    }
  }, []);

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    // isDragAccept,
    // isDragReject
  } = useDropzone({
    onDrop,
  });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      // ...(isDragAccept ? acceptStyle : {}),
      // ...(isDragReject ? rejectStyle : {})
      display: 'inline-flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    }),
    [
      isDragActive,
      // isDragReject,
      // isDragAccept
    ]
  );

  const previewStyle = {
    width: '100%',
    height: '100%',
    backgroundColor: '#f0f0f0',
    borderRadius: '4px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    // marginTop: '10px',
  };

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    setFiles([file]);
    const reader = new FileReader();

    reader.onloadend = () => {
      setImagePreview(reader.result);
    };

    if (file) {
      reader.readAsDataURL(file);
    }
  };

  const customStyles = {
    container: (provided) => ({
      ...provided,
      padding: 0,
      marginRight: 0,
      marginButtom: '10px',
      // overflow: 'scroll',
      // overflow: 'visible',
    }),
    control: (provided) => ({
      ...provided,
      border: '1px solid #71858B',
      borderRadius: '12px',
      width: '98%',
      padding: '5px 5px',
    }),
    option: (
      styles,
      { data, isDisabled, isFocused, isSelected, isHovered }
    ) => ({
      ...styles,
    }),
  };

  useEffect(() => {
    if (
      clickedManuscript.genres === null ||
      clickedManuscript.genres === 'none' ||
      clickedManuscript.genres.length === 0
    ) {
      setSelectedGenres([]);
    } else {
      const allowedGenres = options.flatMap(option => option.options.map(opt => opt.value));
      const allSelectedGenres = clickedManuscript.genres.map(genre => {
        const matchingOption = options.flatMap(option => option.options).find(opt => opt.value === genre);
        return matchingOption ? matchingOption : { value: genre, label: genre };
      });
      setSelectedGenres(allSelectedGenres);
    }
  }, [clickedManuscript]);
  



  
  return (
    <div className={styles.container}>
      <div className={styles.editbox} ref={editboxRef}>
        <div className={styles.boxsplit}>
          <div className={styles.title}>
            <div className={styles.titleText}>Edit Manuscript</div>
            <div className={styles.buttonOut} onClick={handleOut}>
              <img className={styles.xbutton} src={xxx} />
            </div>
          </div>
          <div className={styles.editboxContent}>
            <div className={styles.editboxContentTextNimage}>
              <div className={styles.editboxContentTextPart}>
                <div className={styles.editboxContentTextTitle}>
                  <label className={styles.lable} htmlFor="ManuscriptName">
                    {' '}
                    <div className={styles.labelText}> Manuscript Name </div>
                  </label>
                  <input
                    className={styles.input}
                    type="text"
                    id="ManuscriptName"
                    name="ManuscriptName"
                    placeholder={clickedManuscript.manuscript_name}
                    value={manuscriptNameInputt}
                    onChange={(e) => setManuscriptNameInputt(e.target.value)}
                  />
                </div>
                <div className={styles.editboxContentTextTitle}>
                  <label className={styles.lable} htmlFor="AuthorName">
                    {' '}
                    <div className={styles.labelText}> Author </div>
                  </label>
                  <input
                    className={styles.input}
                    type="text"
                    id="AuthorName"
                    name="AuthorName"
                    placeholder={clickedManuscript.author_name}
                    value={authorNameInputt}
                    onChange={(e) => setAuthorNameInputt(e.target.value)}
                  />
                </div>
                <div className={styles.editboxContentTextTitle}>
                  <label className={styles.lable} for="Email">
                    {' '}
                    <div className={styles.labelText}> Author Email </div>{' '}
                  </label>
                  <input
                    className={styles.input}
                    type="text"
                    id="Email"
                    name="Email"
                    placeholder={clickedManuscript.author_email}
                    value={authorEnailInputt}
                    onChange={(e) => setAuthorEnailInputt(e.target.value)}
                  />
                </div>
                <div className={styles.editboxContentTextTitleGenre}>
                  <label className={styles.lable} htmlFor="Genre">
                    <div className={styles.labelText}>Genre</div>
                  </label>
                  <Select
                    className={`${styles.Select} dropdownMenu`}
                    options={options}
                    value={selectedGenres}
                    onChange={setSelectedGenres}
                    styles={customStyles}
                    isMulti // Enable multiple selection
                    placeholder="Select Genres" // Update the placeholder text
                  />
                </div>
              </div>
            </div>
          </div>

          <div className={styles.buttons}>
            <div className={styles.cancelButtton} onClick={handleCancel}>
              <div className={styles.cancelButttonText}>Cancel</div>
            </div>
            <div className={styles.saveButtton} onClick={handleSave}>
              <div className={styles.saveButttonText}>Save</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Edit;
